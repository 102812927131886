import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

function AccountLink({ setIsOpened }) {
  const [isMouseIn, setIsMouseIn] = useState(false);
  const setIsMouseInOn = () => setIsMouseIn(true);
  const setIsMouseInOff = () => setIsMouseIn(false);
  const { pathname } = useLocation();

  useEffect(() => {
    setIsMouseInOff();
    setIsOpened(false);
  }, [pathname]);

  return (
    <div
      onMouseEnter={setIsMouseInOn}
      onMouseLeave={setIsMouseInOff}
      className="icon_pic"
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Iconly/Light/Profile">
          <g id="Profile">
            <path
              id="Stroke 1"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.9848 15.3462C8.11714 15.3462 4.81429 15.931 4.81429 18.2729C4.81429 20.6148 8.09619 21.2205 11.9848 21.2205C15.8524 21.2205 19.1543 20.6348 19.1543 18.2938C19.1543 15.9529 15.8733 15.3462 11.9848 15.3462Z"
              stroke="var(--primary)"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              id="Stroke 3"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.9848 12.0059C14.5229 12.0059 16.58 9.94782 16.58 7.40972C16.58 4.87163 14.5229 2.81448 11.9848 2.81448C9.44666 2.81448 7.38857 4.87163 7.38857 7.40972C7.38 9.93925 9.42381 11.9973 11.9524 12.0059H11.9848Z"
              stroke="var(--primary)"
              stroke-width="1.42857"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </g>
      </svg>
      {isMouseIn && (
        <ul className="more_acct_op shadow">
          <li>Account</li>
          <Link to={"/dashboard/profile"}>
            <li>Profile</li>
          </Link>
          <li className="flex">
            <span>Sign out</span>
            <div className="svg">
              <svg
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.89535 9.22998C7.45785 9.22998 7.11192 9.56998 7.11192 9.99997C7.11192 10.42 7.45785 10.77 7.89535 10.77H14V15.55C14 18 11.9753 19.9999 9.47238 19.9999H4.51744C2.02471 19.9999 0 18.01 0 15.56V4.44999C0 1.98999 2.03488 0 4.52762 0H9.49273C11.9753 0 14 1.98999 14 4.43999V9.22998H7.89535ZM17.6302 6.54019L20.5502 9.45018C20.7002 9.60018 20.7802 9.79018 20.7802 10.0002C20.7802 10.2002 20.7002 10.4002 20.5502 10.5402L17.6302 13.4502C17.4802 13.6002 17.2802 13.6802 17.0902 13.6802C16.8902 13.6802 16.6902 13.6002 16.5402 13.4502C16.2402 13.1502 16.2402 12.6602 16.5402 12.3602L18.1402 10.7702H14.0002V9.23019H18.1402L16.5402 7.64019C16.2402 7.34019 16.2402 6.85019 16.5402 6.55019C16.8402 6.24019 17.3302 6.24019 17.6302 6.54019Z"
                  fill="black"
                />
              </svg>
            </div>
          </li>
        </ul>
      )}
    </div>
  );
}

export default AccountLink;
