import React, { useState } from "react";
import "../css/testimonials.css";
import swetham from "../assets/testimonials/mobile/swetha.png";
import vijayam from "../assets/testimonials/mobile/vijaya.png";
import vijaylakshimim from "../assets/testimonials/mobile/vijayalakshmi.png";
import swetha from "../assets/testimonials/swetha.png";
import vijaya from "../assets/testimonials/vijaya.png";
import vijaylakshimi from "../assets/testimonials/vijayalakshmi.png";
import arrow from "../assets/arrow.png";
import useWindow from "../hooks/useWindow";

import points_red from "../assets/dotsred.png";
import points_blue from "../assets/points.png";

const testimonials = [
  {
    id: 1,
    name: "Ms. Swetha Batchu",
    message:
      "I have understood that cADRE is the right investment platform for a small entrepreneurial investor like me to earn and practice entrepreneurship. I went ahead and invested, and within a short span of our association with csPILLAI, I am already occupied with good feelings and confident in multiplying my investment. I thank csPILLAI for providing such a wonderful platform! Congrats, and keep it up.",
    web: "manikantatech.me",
    image: swetha,
    mobile: swetham,
  },
  {
    id: 2,
    name: "Ms. Vijaya Musale",
    message:
      "I am a small entrepreneurial investor; I did invest in their cADRE. I wanted my money working before my eyes, and it did unfold before my eyes! csPILLAI is your go-to place if you want to grow your money, business, and entrepreneurial skills. Our experience with the cADRE business of csPILLAI has been great so far!",
    web: "vijayaassemblies.me",
    image: vijaya,
    mobile: vijayam,
  },
  {
    id: 3,
    name: "Ms. VijayaLakshmi Makam",
    message:
      "I happily invested in invest2enterprise service of csPILLAI, they have played a key role in making their cADRE a PAN India business in a short span. csPILLAI has brought an innovative approach to work with small entrepreneurial investors, giving us tools to create name, fame, and revenue. Thanks to each and everyone in the csPILLAI team for their support and dedication.",
    web: "vlinnovations.me",
    image: vijaylakshimi,
    mobile: vijaylakshimim,
  },
];

function Testimonials(props) {
  const [current, setCurrent] = useState(0);
  const { getProp } = useWindow();
  return (
    <section id="testimonials" className="container testimonials sans">
      <h2 className="title">Testimonials</h2>
      <div className="tets">
        <div>
          <div className="box">
            <img
              className={getProp("", "", "img_i")}
              src={getProp(
                testimonials[current].mobile,
                testimonials[current].mobile,
                testimonials[current].image
              )}
              alt=""
            />
            <p>{testimonials[current].message}</p>
            <div>
              <span>{testimonials[current].name}</span>
              <a
                href={`http://${testimonials[current].web}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {testimonials[current].web}
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="arrows">
        <div
          style={{ opacity: current === 0 ? 0.3 : 1 }}
          onClick={() => {
            if (current === 0) return;
            setCurrent(current - 1);
          }}
        >
          <img src={arrow} alt="" />
        </div>
        <div
          style={{ opacity: current === testimonials.length - 1 ? 0.3 : 1 }}
          onClick={() => {
            if (current === testimonials.length - 1) return;
            setCurrent(current + 1);
          }}
        >
          <img src={arrow} alt="" />
        </div>
      </div>
      <img className="prd" src={points_red} alt="" />
      <img className="pb" src={points_blue} alt="" />
    </section>
  );
}

export default Testimonials;
