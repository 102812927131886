import React, { useEffect, useRef, useState } from "react";
import Legal from "./components/Legal";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import MainRoute from "./components/MainRoute";
import GetStarted from "./components/GetStarted";
import AppContext from "./contexts/AppContext";
import Agreement from "./components/Agreement";
import useClient from "./hooks/useClient";
import AcceptCookies from "./components/AcceptCookies";
import Sponsor from "./components/Sponsor";
import Sponsorship from "./components/Sponsorship";
import ShareSite from "./components/ShareSite";
import DashboardRouter from "./components/dashboard/DashboardRouter";

const RenderApp = ({ isScrolled, routes }) => {
  const { pathname } = useLocation();
  return (
    <div className={pathname.includes("/dashboard") ? "" : "app"}>
      <Header isScrolled={isScrolled} />
      <div
        ref={routes}
        className={pathname.includes("/dashboard") ? "" : "routes"}
      >
        <Routes>
          <Route path="/" element={<MainRoute />} />
          <Route path="/get-started" element={<GetStarted />} />
          <Route path="/sponsor" element={<Sponsor />} />
          <Route path="/sponsorship" element={<Sponsorship />} />
          <Route path="/agreement/*" element={<Agreement />} />
          <Route path="/legal/*" element={<Legal />} />
          <Route path="/dashboard/*" element={<DashboardRouter />} />
        </Routes>
        <ShareSite />
        <Footer />
      </div>
      <AcceptCookies />
      {isScrolled && (
        <div
          onClick={() => {
            routes.current.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
          className="arrow_up"
        >
          <svg fill="white" class="w-6 h-6" viewBox="0 0 16 16">
            <path
              fillRule="evenodd"
              d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z"
            ></path>
          </svg>
        </div>
      )}
    </div>
  );
};

function App(props) {
  const [user, setUser] = useState(null);
  const [tokens, setTokens] = useState(null);
  const { isLoading, restoreUser } = useClient();
  const [isScrolled, setIsScrolled] = useState(false);
  const routes = useRef(null);
  const handleScroll = (e) => {
    // eslint-disable-next-line
    const offset = routes.current.scrollTop;
    if (isScrolled && offset > 60) return;
    if (offset > 60) setIsScrolled(true);
    if (offset < 60) setIsScrolled(false);
    //scrolled
  };
  useEffect(() => {
    const a = routes;
    routes.current.addEventListener("scroll", handleScroll);
    return () => {
      a.current?.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    (async () => {
      const user = await restoreUser();
      setUser(user);
    })();
    // eslint-disable-next-line
  }, []);

  if (isLoading) return <div className="app_loader">Loading...</div>;

  return (
    <AppContext.Provider
      value={{ user, setUser, app_loading: isLoading, tokens, setTokens }}
    >
      <BrowserRouter>
        <RenderApp routes={routes} isScrolled={isScrolled} />
      </BrowserRouter>
    </AppContext.Provider>
  );
}

export default App;
