import axios from "axios";
import store from "../utils/store";

//https://cspillai-backend.herokuapp.com

//http://localhost:3000/api/v1/

const client = axios.create({
  // baseURL: "http://192.168.8.100:3000/api/v1/",
  // baseURL: "http://localhost:3000/api/v1/",
  // baseURL: "https://cspillai-live-backend.herokuapp.com/api/v1",
  // baseURL: "https://cspillai-backend.herokuapp.com/api/v1",
  baseURL: "https://cspillai-api.com/api/v1",
  headers: {
    "x-access-token": store.getAccessToken(),
    "x-refresh-token": store.getRefreshToken(),
    // "x-access-token": store.getAccessToken(),
    // "x-refresh-token": store.getRefreshToken(),
  },
});

export default client;
