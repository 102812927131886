import React from "react";
import Benefits from "./Benefits";
import BVB from "./BVB";
import Enquire from "./Enquire";
import FAQs from "./FAQs";
import FlexInfo from "./FlexInfo";
import Home from "./Home";
import Products from "./Products";
import Services from "./Services";
import Testimonials from "./Testimonials";

function MainRoute(props) {
  return (
    <div>
      <Home />
      <FlexInfo />
      <Products />
      <Benefits />
      <Services />
      <Enquire />
      <BVB />
      <FAQs />
      <Testimonials />
    </div>
  );
}

export default MainRoute;
