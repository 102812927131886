import React from "react";

function Copyright(props) {
  return (
    <div className="content lora">
      {/* <h1>Copyright Notice</h1> */}
      <p>
        All files and information contained in this Website or Blog located at
        <a href="http://cspillai.com" target="_blank" rel="noopener noreferrer">
          {" "}
          http://www.cspillai.com.
        </a>{" "}
        are copyright by csPILLAI [ Surie Innovations Pvt. Ltd. ], and may not
        be duplicated, copied, modified or adapted, in any way without our
        written permission. Our Website or Blog may contain our service marks or
        trademarks as well as those of our affiliates or other companies, in the
        form of words, graphics, and logos.
      </p>
      <p>
        Your use of our Website, Blog or Services does not constitute any right
        or license for you to use our service marks or trademarks, without the
        prior written permission of csPILLAI [ Surie Innovations Pvt. Ltd. ].
      </p>
      <p>
        Our Content, as found within our Website, Blog and Services, is
        protected under local and foreign copyrights. The copying,
        redistribution, use or publication by you of any such Content, is
        strictly prohibited. Your use of our Website and Services does not grant
        you any ownership rights to our Content.
      </p>
      <p>
        <strong>Enforcement of copyright</strong>
      </p>
      <p>
        csPILLAI [ Surie Innovations Pvt. Ltd. ] takes the protection of its
        copyright very seriously.
      </p>
      <p>
        If csPILLAI [ Surie Innovations Pvt. Ltd. ] discovers that you have used
        its copyright materials in contravention of the license above, csPILLAI
        [ Surie Innovations Pvt. Ltd. ] may bring legal proceedings against you
        seeking monetary damages and an injunction to stop you using those
        materials.&nbsp; You could also be ordered to pay legal costs.
      </p>
      <p>
        If you become aware of any use of csPILLAI [ Surie Innovations Pvt. Ltd.
        ]&rsquo;s copyright materials that contravenes or may contravene the
        license above, please report this to us immediately.{" "}
      </p>
      <p>
        Copyright &copy; csPILLAI [ Surie Innovations Pvt. Ltd. ] 2020 All
        Rights Reserved
      </p>
    </div>
  );
}

export default Copyright;
