import React, { useState } from "react";
import mfa from "../assets/pdfs/MFA.docx";
import ct from "../assets/pdfs/caretaker_scpillai.pdf";
import cp from "../assets/pdfs/company_profile_digital.pdf";
import sr from "../assets/pdfs/suresh.pdf";

const qs = [
  {
    title: "WHAT IS cADRE",
    id: 1,
    answer: "Download The PDF Here.",
    link: "sr",
  },
  {
    title: "HOW TO BUY FRANCHISE",
    id: 2,
    answer: "Download The PDF Here.",
    link: "mfa",
  },
  {
    title: "ABOUT COMPANY",
    id: 3,
    answer: "Download The PDF Here.",
    link: "cp",
  },
  {
    title: "ABOUT CARETAKER",
    id: 4,
    answer: "Download The PDF Here.",
    link: "ct",
  },
  // {
  //   title: "HOW TO RATE",
  //   id: 5,
  //   answer:
  //     "This is for those who doesn't want to participate in business right away but still just want to ' Register ' for future participation. Just get them registered in a usual way and also we will provide 5 stars and take their review about this business program AND also provide a box and ask them to write how this business can be improved or what is it lacking or what is it that keeping back in investing!?",
  // },
  // {
  //   title: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit?",
  //   id: 6,
  //   answer:
  //     "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur repellat autem eius numquam necessitatibus adipisci quibusdam ex tempora rerum voluptates, perferendis minus magni, optio assumenda laboriosam tempore quasi, cum dicta.",
  // },
  // {
  //   title: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit?",
  //   id: 7,
  //   answer:
  //     "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur repellat autem eius numquam necessitatibus adipisci quibusdam ex tempora rerum voluptates, perferendis minus magni, optio assumenda laboriosam tempore quasi, cum dicta.",
  // },
];

const RenderFAQ = ({ title, answer, link }) => {
  const [isOpened, setIsOpened] = useState(false);
  const files = {
    mfa,
    ct,
    cp,
    sr,
  };
  return (
    <div className="faq_ lato">
      <h2>{title}</h2>
      {isOpened && <p>{link ? <a href={files[link]}>{answer}</a> : answer}</p>}
      <div
        className={`ico ${isOpened ? "ooo" : ""}`}
        onClick={() => setIsOpened(!isOpened)}
      >
        <svg
          width="22"
          height="15"
          viewBox="0 0 22 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.0659 14.3389C10.6799 14.3389 10.3289 14.1813 10.0481 13.8661L1.27432 3.62181C0.747893 2.9914 0.747884 2.00638 1.30941 1.37596C1.87093 0.784942 2.74834 0.784945 3.30986 1.41536L11.0659 10.4776L18.822 1.41536C19.3484 0.784946 20.2609 0.784943 20.8224 1.37596C21.3839 1.96698 21.3839 2.9914 20.8574 3.62182L12.0837 13.8661C11.8029 14.1813 11.4519 14.3389 11.0659 14.3389Z"
            fill="white"
          />
        </svg>
      </div>
    </div>
  );
};

function FAQs(props) {
  return (
    <section className="container faqs" id="faqs">
      <div className="title">FAQs</div>
      <div className="sub">
        Find the answers to anything you need in our Help Center
      </div>

      <div className="fqs_">
        {qs.map((fq, idx) => (
          <RenderFAQ {...fq} key={idx} />
        ))}
      </div>
    </section>
  );
}

export default FAQs;
