import React from "react";

function Terms(props) {
  return (
    <div className="content lora">
      <h2>Introduction</h2>
      <p>
        The Terms and Conditions (&ldquo;<strong>Terms</strong>&rdquo;) describe
        how
        <strong>
          {" "}
          csPILLAI [ Surie Innovations Pvt. Ltd. ] in India
          (&ldquo;Company,&rdquo; &ldquo;we,&rdquo; and &ldquo;our&rdquo;)
        </strong>{" "}
        regulates your use of this website{" "}
        <a href="http://cspillai.com" target="_blank" rel="noopener noreferrer">
          {" "}
          http://www.cspillai.com.
        </a>{" "}
        (the &ldquo;
        <strong>Website</strong>&rdquo;). Please read the following information
        carefully to understand our practices regarding your use of the website.
        The Company may change the Terms at any time. The Company may inform you
        of the changes to the Terms using the available means of communication.
        The Company recommends you to check the website frequently to see the
        actual version of the Terms and their previous versions.
      </p>
      <p>
        If you represent a legal entity, you certify that you entitled by such a
        legal entity to conclude the Terms as the legal entity you represent.
      </p>

      <h2>Privacy Policy</h2>
      <p>
        Our Privacy Policy is available on a separate page. Our Privacy Policy
        explains to you how we process information about you. You shall
        understand that through your use of the website you acknowledge the
        processing of this information shall be undertaken in accordance with
        the Privacy Policy.{" "}
      </p>
      <h2>Your Account</h2>
      <p>
        When using the website, you shall be responsible for ensuring the
        confidentiality of your account, password and other credentials and for
        secure access to your device. You shall not assign your account to
        anyone. The Company is not responsible for unauthorized access to your
        account that results from misappropriation or theft of your account. The
        Company may refuse or cancel service, terminate your account, and remove
        or edit content.
      </p>
      <p>
        The Company does not knowingly collect personal data from persons under
        the age of 16 (sixteen). If you are under 16 (sixteen) years old, you
        may not use the website and may not enter into the Terms under any
        circumstances.
      </p>

      <h2>Services</h2>
      <p>
        The website allows you to use Services available on the website. You
        shall not use the services for the illegal aims.
      </p>
      <p>
        We may, at our sole discretion, set fees for using the website for you.
        All prices are published separately on relevant pages on the website. We
        may, at our sole discretion, at any time change any fees.
      </p>
      <p>
        We may use certified payment systems, which also may have their
        commissions. Such commissions may be implied on you when you choose a
        particular payment system. Detailed information about commissions of
        such payment systems may be found on their websites.<strong></strong>
      </p>

      <h2>Third Party Services</h2>
      <p>
        The website may include links to other websites, applications, and
        platforms (hereinafter the &quot;<strong>Linked Sites</strong>&quot;).
      </p>
      <p>
        The Company does not control the Linked Sites, and shall not be
        responsible for the content and other materials of the Linked Sites. The
        Company makes these links available to you for providing the
        functionality or services on the website.{" "}
      </p>
      <h2>Prohibited Uses and Intellectual Property</h2>
      <p>
        The Company grants you a non-transferable, non-exclusive, revocable
        license to access and use the website from one device in accordance with
        the Terms. You shall not use the website for unlawful or prohibited
        purpose. You may not use the website in a way that may disable, damage,
        or interfere in the website.
      </p>
      <p>
        All content present on the website includes text, code, graphics, logos,
        images, compilation, software used on the website (hereinafter and
        hereinbefore the &quot;<strong>Content</strong>&quot;). The Content is
        the property of the Company or its contractors and protected by
        intellectual property laws that protect such rights. You agree to use
        all copyright and other proprietary notices or restrictions contained in
        the Content and you are prohibited from changing the Content.
      </p>
      <p>
        You may not publish, transmit, modify, reverse engineer, participate in
        the transfer, or create and sell derivative works, or in any way use any
        of the Content. Your enjoyment of the website shall not entitle you to
        make any illegal and disallowed use of the Content, and in particular
        you shall not change proprietary rights or notices in the Content. You
        shall use the Content only for your personal and non-commercial use. The
        Company does not grant you any licenses to the intellectual property of
        the Company.
      </p>
      <h2>The Company Materials</h2>
      <p>
        By posting, uploading, inputting, providing or submitting your Content
        you are granting the Company to use your Content in connection with the
        operation of Company's business including, but not limited to, the
        rights to transmit, publicly display, distribute, publicly perform,
        copy, reproduce, and translate your Content; and to publish your name in
        connection with your Content.
      </p>
      <p>
        No compensation shall be paid with regard to the use of your Content.
        The Company shall have no obligation to publish or enjoy any Content you
        may send us and may remove your Content at any time.
      </p>
      <p>
        By posting, uploading, inputting, providing or submitting your Content
        you warrant and represent that you own all of the rights to your
        Content.<strong> </strong>
      </p>
      <h2>Disclaimer of Certain Liabilities</h2>
      <p>
        The information available via the website may include typographical
        errors or inaccuracies. The Company shall not be liable for these
        inaccuracies and errors.
      </p>
      <p>
        The Company makes no representations about the availability, accuracy,
        reliability, suitability, and timeliness of the Content contained on and
        services available on the website. To the maximum extent allowed by the
        applicable law, all such Content and services are provided on the
        &quot;as is&quot; basis. The Company disclaims all warranties and
        conditions regarding this Content and services, including warranties and
        provisions of merchantability, fitness for a certain purpose.
      </p>
      <p>
        To the maximum extent permitted by the applicable law, in no event shall
        the Company be liable for any direct, indirect, incidental,
        consequential, special, punitive damages including, but not limited to,
        damages for loss of enjoyment, data or profits, in the connection with
        the enjoyment or execution of the website in the context of the
        inability or delay to enjoy the website or its services, or for any
        Content of the website, or otherwise arising out of the enjoyment of the
        website, based on contract and non-contract liability or other reason.
      </p>
      <p>
        If the exclusion or limitation of liability for damages, whether
        consequential or incidental, are prohibited in a particular case, the
        exclusion or limitation of liability shall not apply to you.
        <strong></strong>
      </p>
      <h2>Indemnification</h2>
      <p>
        You agree to indemnify, defend and hold harmless the Company, its
        managers, directors, employees, agents, and third parties, for any
        costs, losses, expenses (including attorneys' fees), liabilities
        regarding or arising out of your enjoyment of or inability to enjoy the
        website or its services and Company&rsquo;s services and products, your
        violation of the Terms or your violation of any rights of third parties,
        or your violation of the applicable law. The Company may assume the
        exclusive defense and you shall cooperate with the Company in asserting
        any available defenses.
      </p>
      <h2>Termination and Access Restriction</h2>
      <p>
        The Company may terminate your access and account to the website and its
        related services or any part at any time, without notice, in case of
        your violation of the Terms.
      </p>
      <h2>Miscellaneous</h2>
      <p>
        The governing law of the Terms shall be the substantive laws of the
        country where the Company is set up, except the conflict of laws rules.
        You shall not use the website in jurisdictions that do not give effect
        to all provisions of the Terms.
      </p>
      <p>
        No joint venture, partnership, employment, or agency relationship shall
        be implied between you and the Company as a result of the Terms or use
        of the website.
      </p>
      <p>
        Nothing in the Terms shall be a derogation of the Company's right to
        comply with governmental, court, police, and law enforcement requests or
        requirements regarding your enjoyment of the website.
      </p>
      <p>
        If any part of the Terms is determined to be void or unenforceable in
        accordance with applicable law then the void or unenforceable clauses
        will be deemed superseded by valid and enforceable clauses shall be
        similar to the original version of the Terms and other parts and
        sections of the Terms shall be applicable to you and the Company.
      </p>
      <p>
        The Terms constitute the entire agreement between you and the Company
        regarding the enjoyment of the website and the Terms supersede all prior
        or communications and offers, whether electronic, oral or written,
        between you and the Company.
      </p>
      <p>
        The Company and its affiliates shall not be liable for a failure or
        delay to fulfill its obligations where the failure or delay results from
        any cause beyond Company's reasonable control, including technical
        failures, natural disasters, blockages, embargoes, riots, acts,
        regulation, legislation, or orders of government, terroristic acts, war,
        or any other force outside of Company's control.
      </p>
      <p>
        In case of controversies, demands, claims, disputes, or causes of action
        between the Company and you relating to the website or other related
        issues, or the Terms, you and the Company agree to attempt to resolve
        such controversies, demands, claims, disputes, or causes of action by
        good faith negotiation, and in case of failure of such negotiation,
        exclusively through the courts of the country where the Company is set
        up.
      </p>

      <h2>Complaints</h2>
      <p>
        We are committed to resolve any complaints about our collection or use
        of your personal data. If you would like to make a complaint regarding
        this Terms or our practices in relation to your personal data, please
        contact us through our website. We will reply to your complaint as soon
        as we can and in any event, within 30 days. We hope to resolve any
        complaint brought to our attention, however if you feel that your
        complaint has not been adequately resolved, you reserve the right to
        contact your local data protection supervisory authority.
      </p>

      <h2>Contact Information</h2>
      <p>
        We welcome your comments or questions about our Terms. You may contact
        us through the contact information available on our website
        <strong></strong>.
      </p>
    </div>
  );
}

export default Terms;
