import React from "react";
import { Link } from "react-router-dom";

function NavBar({ isOpened }) {
  return (
    <nav className={isOpened ? "opened" : ""}>
      <ul>
        <Link to={"/dashboard"}>
          <li>Home</li>
        </Link>
        <Link to={"/dashboard/profile"}>
          <li>Profile</li>
        </Link>
        <Link to={"/dashboard/documents"}>
          <li>Documents</li>
        </Link>
        <Link className="lll" to={"/dashboard/settings"}>
          <li>Settings</li>
        </Link>
      </ul>
    </nav>
  );
}

export default NavBar;
