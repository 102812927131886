import React, { useEffect } from "react";
import "../css/legal.css";
import orange from "../assets/orange.png";
import PrivacyPolicy from "./legal/PrivacyPolicy";
import Copyright from "./legal/Copyright";
import Disclaimer from "./legal/Disclaimer";
import TermsOfUse from "./legal/TermsOfUse";
import Terms from "./legal/Terms";
import { Route, Routes, useLocation, useParams } from "react-router-dom";
import RefundPolicy from "./legal/RefundPolicy";

const policies = [
  { title: "Privacy Policy", element: <PrivacyPolicy /> },

  {
    title: "Terms Of-Service",
    element: <TermsOfUse />,
    subtitle: "Effective as of May 28, 2020",
  },

  { title: "Earnings Disclaimer", element: <Disclaimer /> },
  { title: "Terms", element: <Terms />, last_update: "May 28, 2020" },
  { title: "Copyright Notice", element: <Copyright /> },
  { title: "Refund Policy", element: <RefundPolicy /> },
];

function Legal(props) {
  const route = useParams();
  const el = policies.find(
    (a) => a.title.toLowerCase().replace(" ", "-") === route["*"]
  );
  const a = useLocation();

  useEffect(() => {
    const d = document.getElementById("rrr__");
    if (!d) return;
    d?.scrollIntoView({ behavior: "smooth" });
    // console.log(a.pathname);
  }, [a.pathname]);

  return (
    <div id="" className="sans">
      <div className="bg_">
        <div className="rru">
          <h1 id="rrr__">
            <strong>{el.title}</strong>
          </h1>
          {el.subtitle ? <p>{el.subtitle}</p> : <></>}
          {el.last_update ? (
            <h3>
              Last Updated:<strong> May 28, 2020</strong>
            </h3>
          ) : (
            <></>
          )}
        </div>
        <img src={orange} alt="" />
      </div>
      <Routes>
        {policies.map((p, idx) => (
          <Route
            key={idx}
            path={`/${p.title.toLowerCase().replace(" ", "-")}`}
            element={p.element}
          />
        ))}
      </Routes>
    </div>
  );
}

export default Legal;

/**
 * 
 *  <h1>Terms of Use</h1>
      <p>Effective as of May 28, 2020.</p>
 * 
 */
