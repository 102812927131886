import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import Header from "./com/Header";
import Dashboard from "./pages/Dashboard";
import "../../css/dashboard.css";
import "../../css/colors.css";
import NavBar from "./com/NavBar";
import Profile from "./pages/Profile";

function DashboardRouter(props) {
  const [isOpened, setIsOpened] = useState(false);
  return (
    <div className="app_d lato">
      <Header setIsOpened={setIsOpened} isOpened={isOpened} />
      <div className="routes_d">
        <NavBar isOpened={isOpened} />
        <div className="rrr">
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/profile" element={<Profile />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default DashboardRouter;
