import React from "react";
import AccountLink from "./AccountLink";
import NotificationIcon from "./NotificationIcon";

function Header({ isOpened, setIsOpened }) {
  return (
    <header className="d_header lato">
      <div
        onClick={() => setIsOpened(!isOpened)}
        className={`menu ${isOpened ? "opened" : ""}`}
      >
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className="user_inf">
        <NotificationIcon />
        <h2>Ismail Dalhatu</h2>
        <AccountLink setIsOpened={setIsOpened} />
      </div>
    </header>
  );
}

export default Header;
