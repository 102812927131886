import React, { useState } from "react";
import "../css/sponsor.css";
import useClient from "../hooks/useClient";
import { useSearchParams } from "react-router-dom";
import urls from "../api/urls";

function Sponsorship() {
  const [amount, setAmount] = useState("");
  const [firstName, set_firstName] = useState("");
  const [lastName, set_lastName] = useState("");
  const [email, set_email] = useState("");
  const [phone, set_phone] = useState("");
  const [paymentDetails, set_paymentDetails] = useState("");
  const [address, set_address] = useState("");
  const [tokenFor, set_tokenFor] = useState("");
  const [alertMessage, setAlertMessage] = useState({
    message: "",
    type: "",
  });
  const [searchParams] = useSearchParams();
  const { isLoading, post } = useClient();

  const ref = searchParams.get("ref");
  const service = searchParams.get("service");

  const saveSponsorship = async () => {
    if (!amount)
      return setAlertMessage({
        type: "error",
        message: "Please add an Amount",
      });

    const res = await post(urls.sponsorships, {
      firstName,
      lastName,
      email,
      ref,
      address,
      amount: parseFloat(amount),
      phone,
      paymentDetails,
      tokenFor,
      service,
    });
    if (res.error)
      return setAlertMessage({ type: "error", message: res.error?.message });
    setAlertMessage({ type: "success", message: "Token created successfully" });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    saveSponsorship();
  };

  return (
    <div className="sponsor_container">
      <div className="content_ lato container">
        <h2 className="title">Amount</h2>
        <ul className="amt">
          <li className="flex ">
            <span className="pn">INR</span>
            <input
              className="imput_type"
              placeholder="Amount"
              onChange={(e) => setAmount(e.target.value)}
              type="number"
            />
          </li>
        </ul>

        <form className="form sp_fm" onSubmit={handleSubmit}>
          <h3>CREATE TOKEN</h3>
          <div className="inputs">
            <input
              name="firstName"
              required
              value={firstName}
              onChange={(e) => set_firstName(e.target.value)}
              type="text"
              placeholder="First Name"
            />
            <input
              name="lastName"
              required
              value={lastName}
              onChange={(e) => set_lastName(e.target.value)}
              type="text"
              placeholder="Last Name"
            />
          </div>
          <div className="inputs">
            <input
              required
              name="email"
              value={email}
              onChange={(e) => set_email(e.target.value)}
              type="email"
              placeholder="Email"
            />
            <input
              required
              name="phone"
              value={phone}
              onChange={(e) => set_phone(e.target.value)}
              type="text"
              placeholder="Phone Number"
            />
          </div>
          <br />
          <input
            type="text"
            placeholder="Address"
            name="address"
            required
            value={address}
            onChange={(e) => set_address(e.target.value)}
          />
          <br />
          <input
            type="text"
            placeholder="Token for"
            name="tokenFor"
            required
            value={tokenFor}
            onChange={(e) => set_tokenFor(e.target.value)}
          />
          <textarea
            value={paymentDetails}
            onChange={(e) => set_paymentDetails(e.target.value)}
            placeholder="Payment details"
            name="paymentDetails"
          ></textarea>
          <div className={`c_ms ${alertMessage?.type}`}>
            {alertMessage?.message}
          </div>
          <input
            disabled={isLoading}
            type="submit"
            value={isLoading ? "loading..." : "Send"}
          />
        </form>
      </div>
    </div>
  );
}

export default Sponsorship;
