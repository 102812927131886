import React, { useEffect, useRef, useState } from "react";
import p_d from "../assets/p_d.png";
import p_m from "../assets/p_m.png";
import points from "../assets/points_purple.png";
import "../css/services.css";
import useWindow from "../hooks/useWindow";

const slides = [
  {
    id: 4,
    title: "INVEST2EXIT",
    for: "Start-up Building & Selling",
    wwm: "csPILLAI + INVESTOR",
    wii: "Scaleup Investors",
    wtg: ["Trade Sale Exit", "<540 Days Exit", "2x To 10x ROI"],
    htw: "Fundingbystakeholders",
    abm: "Venture Building + Reverse Engineering  + Fundraising",
    // image: iex,
    q: "Build",
  },
  {
    id: 2,
    title: "INVEST2MULTIPLY",
    for: "Start-up Scaling & Selling",
    wwm: "csPILLAI + INVESTOR",
    wii: "Scaleup Investors",
    wtg: ["SME IPO exit ", "<1080 days exit", "10x to 100x ROI"],
    htw: "Fundingbyprivateequity",
    abm: "Fundraising + Manufacturing  + eMarketplace",
    // image: im,
    q: "Scale",
  },
  {
    id: 1,
    title: "INVEST2ENTERPRISE",
    for: "Manufacturing Franchising",
    wwm: "csPILLAI + CLIENT",
    wii: "Entrepreneurial investors Or [New entrepreneurs] Or [You!]",
    wtg: ["cADRE cATT", "1800 days franchise", "23% CAGR"],
    htw: "Manufacturingbymasses",
    abm: "Manufacturing franchise + Space-as-a-service",
    ga: "Rural-Development",
    // image: ien,
    q: " Oversee Manufacturing",
  },

  {
    id: 3,
    title: "INVEST2EXCEL",
    for: "Manufacturing Franchising",
    wwm: "csPILLAI + INVESTOR",
    wii: "Mentoring Investors",
    wtg: ["Mentorship", "Management", "Money"],
    htw: "Underpiningbyassociates",
    abm: "Manufacturing Franchise + Fundraising",
    // image: iec,
    q: "Manufacture",
  },
];

// const RenderSvg = ({ ...props }) => (
//   <div {...props}>
//     <svg
//       width="7"
//       height="10"
//       viewBox="0 0 7 10"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M6.82877 5.02355C6.82877 5.19797 6.75043 5.35653 6.59377 5.48338L1.50213 9.44734C1.1888 9.68518 0.699224 9.68518 0.385892 9.43149C0.0921442 9.17779 0.0921454 8.78139 0.405477 8.52769L4.90962 5.02355L0.405477 1.5194C0.0921455 1.28156 0.0921443 0.869314 0.385892 0.615621C0.679641 0.361927 1.1888 0.36195 1.50213 0.599788L6.59377 4.56373C6.75043 4.69058 6.82877 4.84914 6.82877 5.02355Z"
//         fill="white"
//       />
//     </svg>
//   </div>
// );

function Services(props) {
  const { getProp } = useWindow();
  const [active, setActive] = useState(2);
  const refs = [
    [useRef(null), useRef(null)],
    [useRef(null), useRef(null)],
    [useRef(null), useRef(null)],
    [useRef(null), useRef(null)],
    [useRef(null), useRef(null)],
    [useRef(null), useRef(null)],
    [useRef(null), useRef(null)],
  ];
  useEffect(() => {
    refs.forEach((rs) => {
      rs[0].current.style.height = `${rs[1].current.clientHeight}px`;
    });
    // eslint-disable-next-line
  }, [active]);
  return (
    <section id="service" className="services lato">
      <h2 className="title">The Service</h2>
      <br />
      <div className="bg_s flex">
        <div className="container">
          <span className="pnv">Become an Investor</span>
          <br />
          <br />
          <h2>#{slides[active].title}</h2>
          <br />
          <br />
          <p>
            Invest in manufacturing franchise, allow masses to manufacture, and
            earn.
            <br />
            "YOU INVEST, WE MANUFACTURE; WE MARKET, YOU BENIFIT"
          </p>
          {/* <div className="flex nfv">
            <RenderSvg
              onClick={() => {
                if (active === 0) return;
                setActive(active - 1);
              }}
              className="igo left"
            />
            <RenderSvg
              onClick={() => {
                if (active === slides.length - 1) return;
                setActive(active + 1);
              }}
              className="igo right"
            />
            
          </div> */}
        </div>
        <img src={getProp(p_m, p_d, p_d)} alt="" />
      </div>
      <div className="s_inf container flex">
        <div className="qs_">
          {[
            "What Is It For",
            `Who will ${slides[active].q}`,
            "Who Is The Client",
            "What They Get",
            "How-It-Works",
            "Adopted Business Model(s)",
            "Goal Achieved",
          ].map((i, idx) => (
            <React.Fragment key={idx}>
              <div ref={refs[idx][0]} key={idx}>
                <span>{i}</span>
              </div>
              {idx === 3 ? <br /> : <></>}
            </React.Fragment>
          ))}
        </div>
        <div className="qs_ ans">
          <div ref={refs[0][1]}>
            <span>{slides[active].for}</span>
          </div>
          <div ref={refs[1][1]}>
            <span>{slides[active].wwm}</span>
          </div>
          <div ref={refs[2][1]}>
            <span>{slides[active].wii}</span>
          </div>
          <div ref={refs[3][1]}>
            {slides[active].wtg.map((i, idx) => (
              <span key={idx}>{i}</span>
            ))}
          </div>
          <br />
          <div ref={refs[4][1]}>
            <span>{slides[active].htw}</span>
          </div>
          <div ref={refs[5][1]}>
            <span>{slides[active].abm}</span>
          </div>
          <div ref={refs[6][1]}>
            <span>{slides[active].ga}</span>
          </div>
        </div>
        <img src={points} className="desktop ft impp" alt="" />
        <img src={points} className="desktop sd impp" alt="" />
      </div>
    </section>
  );
}

export default Services;
