import React from "react";
import "../css/benefits.css";
import points from "../assets/points.png";
import points_black from "../assets/points_black.png";
import ben from "../assets/ben.png";
import ben01 from "../assets/ben01.png";
import pointsBlue from "../assets/points_blue.png";

const bens = [
  "Reasonable profit margins (50% markup) imply that you will profit more from each order you invest in to assemble or manufacture csPILLAI goods. That means you'll have more money to invest in your business.",
  "The most sophisticated microworkplace or cATT ownership for assembly or manufacturing",
  "cADRE App for the workplace and the micro-entrepreneur to manage employees, management, suppliers, and others",
  "Complete employee and workplace development",
];

const RenderInfo = ({ className = "" }) => {
  return (
    <div className={` ${className}`}>
      {bens.map((b, idx) => (
        <div className="bnf flex" key={idx}>
          {!(idx % 2) ? <img className="lf" src={points} alt="" /> : <></>}
          <h2>{idx + 1}.</h2>
          <p>{b}</p>
          {idx % 2 ? <img className="rr" src={points} alt="" /> : <></>}
        </div>
      ))}
    </div>
  );
};

function Benefits(props) {
  return (
    <section id="benefits" className="sans benefits">
      <div className="title">
        Benifits of Becoming an Entrepreneurial Investor (or) a Manufacturing
        Franchise Partner (or) a Micro-entrepreneur (or) a Rural Development
        Crusader
      </div>
      <div className="container bg_cnt">
        <div className="balls"></div>
        <div className="balls"></div>
        <div className="balls"></div>
        <div className="balls"></div>
        <div className="cn">
          <img className="jjj" src={ben} alt="" />
          <img className="jjj blue__" src={pointsBlue} alt="" />
          <img className="jjj" src={ben01} alt="" />
        </div>
        <RenderInfo className="desktop inn" />
        <img src={points_black} alt="" className="desktop ppb" />
      </div>
      <RenderInfo className="container mobile" />
    </section>
  );
}

export default Benefits;
