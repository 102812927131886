import React from "react";

function Disclaimer(props) {
  return (
    <div className="content lora">
      {/* <h1></h1> */}
      <p>
        While we make every effort to ensure that we accurately represent all
        the products and services reviewed on this website and their potential
        for income, it should be noted that earnings and income statements made
        by csPILLAI [ Surie Innovations Pvt. Ltd. ] and its advertisers /
        sponsors are estimates only of what we think you can possibly earn.
        There is no guarantee that you will make these levels of income and you
        accept the risk that the earnings and income statements differ by
        individual.
      </p>
      <p>
        As with any business, your results may vary, and will be based on your
        individual capacity, business experience, expertise, and level of
        desire. There are no guarantees concerning the level of success you may
        experience. The testimonials and examples used are exceptional results,
        which do not apply to the average purchaser, and are not intended to
        represent or guarantee that anyone will achieve the same or similar
        results. Each individual&rsquo;s success depends on his or her
        background, dedication, desire and motivation.
      </p>
      <p>
        Any claims made on this site of actual earnings or examples of actual
        results can be verified upon request. Your level of success in attaining
        the results claimed in our materials depends on the time you devote to
        the programs, ideas and techniques mentioned, your finances, previous
        knowledge and various skills. Since these factors differ according to
        individuals, we cannot guarantee your success or income level. Nor are
        we responsible for any of your actions.
      </p>
      <p>
        Materials in the product and on our website may contain information that
        includes or is based upon forward-looking statements within the meaning
        of the Securities Litigation Reform Act of 1995. Forward-Looking
        Statements give our expectations or forecasts of future events. You can
        identify these statements by the fact that they do not relate strictly
        to historical or current facts. Such Forward Looking Statements use
        words such as &quot;anticipate,&quot; &quot;estimate,&quot;
        &quot;expect,&quot; &quot;project,&quot; &quot;intend,&quot;
        &quot;plan,&quot; &quot;believe,&quot; and other such words and terms of
        similar meaning in connection with a description of potential earnings
        or financial performance. Your Results May Vary from ours and those of
        anyone else who is, or will be using these products, techniques, and/or
        services.
      </p>
      <p>
        Any and all forward looking statements here or on any of our sales
        material are intended to express our opinion of earnings potential not
        actual earnings expected. Many factors will be important in determining
        your actual results and no guarantees are made, expressed or implied
        that you will achieve results similar to ours or anybody else's. In fact
        no guarantees are made that you will achieve any results from our ideas
        and techniques in our material at all.
      </p>
      <p>
        The use of our information, products and services should be based on
        your own due diligence and you agree that csPILLAI [ Surie Innovations
        Pvt. Ltd. ] and the advertisers / sponsors of this website are not
        liable for any success or failure of your business that is directly or
        indirectly related to the purchase and use of our information, products
        and services reviewed or advertised on this website.
      </p>
    </div>
  );
}

export default Disclaimer;
