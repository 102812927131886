import React from "react";
import shape01 from "../assets/shape01.png";
import points from "../assets/points.png";

const infs = [
  {
    number: "27",
    title: "27 Years of R&D background",
  },
  {
    number: "18",
    title: "Tech areas expertise",
  },
  {
    number: "100+",
    title: "Remote micro R&D centers",
  },
];

function FlexInfo(props) {
  return (
    <div className="infs__ container sans">
      <img className="points_m mobile" src={points} alt="" />
      <img className="points_m top mobile" src={points} alt="" />
      {infs.map((i, idx) => (
        <div key={idx}>
          <h2>{i.number}</h2> <span>{i.title}</span>
        </div>
      ))}
      <img src={shape01} alt="" className="shape0" />
      <img src={shape01} alt="" className="shape0 topr mobile" />
    </div>
  );
}

export default FlexInfo;
