import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import urls from "../api/urls";
import AppContext from "../contexts/AppContext";
import useClient from "../hooks/useClient";
import store from "../utils/store";
import Loader from "./Loader";

// first_name,
// last_name,
// organisation_name,
// phone_number,
// profile_image: profile_img_key,
// address,
// state,
// about,
// zip_code,
// city,
// mailing_address,
// signature: signature_img_key,
// cell_evening,

/**
 * id: Joi.number().required(),
      first_name: Joi.string().allow(""),
      last_name: Joi.string().allow(""),
      organisation_name: Joi.string().allow(""),
      profile_image: Joi.string().allow(""),
      phone_number: Joi.string().required(),
      about: Joi.string().required(),
      state: Joi.string().required(),
      address: Joi.string().required(),
      zip_code: Joi.string().required(),
      mailing_address: Joi.string().email().required(),
      cell_evening: Joi.string().required(),
      signature: Joi.string().required(),
      city: Joi.string().required(),
 */

const RenderDoc = ({ remove, item, extraDocuments, setExtraDocuments }) => {
  const [selectedImage, setSelectedImage] = useState(item.file || "");
  const [title, setTitle] = useState(item.title || "");

  useEffect(() => {
    setExtraDocuments(
      extraDocuments.map((i, ix) => {
        if (i.id === item.id) {
          i.title = title;
        }
        return i;
      })
    );
    // eslint-disable-next-line
  }, [title]);
  useEffect(() => {
    setExtraDocuments(
      extraDocuments.map((i, ix) => {
        if (i.id === item.id) {
          i.file = selectedImage;
        }
        return i;
      })
    );
    // eslint-disable-next-line
  }, [selectedImage]);

  return (
    <div className="add_doc flex">
      <div className="inputs">
        <input
          onChange={(e) => setSelectedImage(e.target.files[0])}
          type="file"
          accept="image/*"
          placeholder="Signature"
        />
        <input
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          type="text"
          placeholder="Title"
        />
      </div>
      <button
        onClick={(e) => {
          e.preventDefault();
          remove();
        }}
      >
        Remove
      </button>
    </div>
  );
};

function Agreement(props) {
  const { user } = useContext(AppContext);
  const [phone_number, set_phone_number] = useState(user?.phone_number || "");
  const [zip_code, set_zip_code] = useState(user?.zip_code || "");
  const [state, set_state] = useState(user?.state || "");
  const [age, set_age] = useState(user?.age || "");
  const [city, set_city] = useState(user?.city || "");
  const [profile_image, set_profile_image] = useState(
    user?.profile_image || null
  );
  const [signature_image, set_signature_image] = useState(
    user?.signature || null
  );
  const [address, set_address] = useState(user?.address || "");
  const [adadhaar_card_f, set_adadhaar_card_f] = useState("");
  const [adadhaar_card_b, set_adadhaar_card_b] = useState("");
  const [pan_card, set_pan_card] = useState("");
  const [extraDocuments, setExtraDocuments] = useState([]);
  const [alertMessage, setAlertMessage] = useState({
    message: "",
    type: "",
  });
  const navigate = useNavigate();
  const { isLoading, post, patch, app_loading } = useClient();

  const signMfa = async () => {
    let aadhaarCardFront = "h";
    let aadhaarCardBack = "h";
    let panCard = "h";
    const cards = [
      { img: adadhaar_card_f, title: "Aadhaar card front" },
      { img: adadhaar_card_b, title: "Aadhaar card back" },
      { img: pan_card, title: "Pan card" },
    ];
    for (let idx = 0; idx < cards.length; idx++) {
      const el = cards[idx];
      if (!el.img.type.includes("image"))
        return setAlertMessage({
          type: "error",
          message: `${el.title} must be an Image`,
        });

      const aformData = new FormData();
      aformData.append("file", el.img);
      const afileName = await post(urls.uploads, aformData);
      if (afileName.error)
        return setAlertMessage({
          visible: true,
          message: afileName.error.message,
          title: `${el.title} could not uploaded`,
        });
      console.log(afileName);
      if (idx === 0) {
        aadhaarCardFront = afileName.data.key;
      } else if (idx === 1) {
        aadhaarCardBack = afileName.data.key;
      } else {
        panCard = afileName.data.key;
      }
    }
    const docs = [];
    for (let idx = 0; idx < extraDocuments.length; idx++) {
      const el = extraDocuments[idx];
      if (!el.file.type.includes("image"))
        return setAlertMessage({
          type: "error",
          message: `${el.title} must be an Image`,
        });

      const aformData = new FormData();
      aformData.append("file", el.file);
      const afileName = await post(urls.uploads, aformData);
      if (afileName.error)
        return setAlertMessage({
          visible: true,
          message: afileName.error.message,
          title: `${el.title} could not uploaded`,
        });
      docs.push({
        title: el.title,
        url: afileName.data.key,
      });
    }
    const res = await post(urls.i2e.mfa, {
      payload: [
        {
          title: "aadhaarCardFront",
          url: aadhaarCardFront,
        },
        {
          title: "aadhaarCardBack",
          url: aadhaarCardBack,
        },
        {
          title: "panCard",
          url: panCard,
        },
        ...docs,
      ],
    });
    if (res.error)
      return setAlertMessage({
        type: "error",
        message: res.error.message,
      });
    setAlertMessage({
      type: "success",
      message: res.data.message,
    });
    console.log(res);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // return console.log(user);
    let profile_img_key = user?.profile_image || store.getItem("pimg") || "";
    let signature_img_key = user?.signature || store.getItem("simg") || "";
    if (!signature_img_key) {
      if (!signature_image.type.includes("image"))
        return setAlertMessage({
          type: "error",
          message: "Signature Image Must Be an Image",
        });

      const sformData = new FormData();
      sformData.append("file", signature_image);
      const sfileName = await post(urls.uploads, sformData);
      if (sfileName.error)
        return setAlertMessage({
          visible: true,
          message: sfileName.error.message,
          title: "Profile not uploaded",
        });
      signature_img_key = sfileName.data.key;
    }
    if (!profile_img_key) {
      if (profile_image) {
        if (!profile_image.type.includes("image"))
          return setAlertMessage({
            type: "error",
            message: "Profile Image Must Be an Image",
          });

        const pformData = new FormData();
        pformData.append("file", profile_image);
        const pfileName = await post(urls.uploads, pformData);
        if (pfileName.error)
          return setAlertMessage({
            visible: true,
            message: pfileName.error.message,
            title: "Profile not uploaded",
          });
        profile_img_key = pfileName.data.key;
      }
    }
    const res = await patch(urls.me, {
      first_name: user.first_name,
      last_name: user.last_name,
      organisation_name: user.organisation_name,
      phone_number,
      profile_image: profile_img_key,
      address,
      state,
      about: "null",
      zip_code,
      city,
      mailing_address: user.email,
      signature: signature_img_key,
      cell_evening: phone_number,
      age: parseInt(age),
    });
    if (res.error) {
      // if (profile_img_key) {
      //   store.setItem("pimg", profile_img_key);
      // }
      // if (signature_img_key) {
      //   store.setItem("simg", signature_img_key);
      // }
      console.log(res.error);
      return setAlertMessage({ message: res.error.message, type: "error" });
    }
    signMfa();
  };

  useEffect(() => {
    if (user) {
      set_phone_number(user.phone_number);
      set_state(user.state);
      set_city(user.city);
      set_zip_code(user.zip_code);
      set_address(user.address);
      set_profile_image(user.profile_image);
      set_signature_image(user.signature);
    } else {
      navigate("/get-started");
    }
    // eslint-disable-next-line
  }, [user]);

  if (app_loading) return <Loader />;
  return (
    <section className="poppins get_started container">
      <form onSubmit={handleSubmit} className="form ">
        <h2 style={{ fontWeight: "900", textAlign: "center" }}>
          Please fill in the details to sign MFA
        </h2>
        <br />
        <div className="inputs">
          {user && !user.zip_code ? (
            <input
              value={zip_code}
              onChange={(e) => set_zip_code(e.target.value)}
              required
              name="zip code"
              type="text"
              className="lato"
              placeholder="Zip Code"
            />
          ) : (
            <></>
          )}
          {user && !user.phone_number ? (
            <input
              required
              value={phone_number}
              onChange={(e) => set_phone_number(e.target.value)}
              name="phone_number"
              type="text"
              className="lato"
              placeholder="Phone Number"
            />
          ) : (
            <></>
          )}
        </div>
        <div className="inputs">
          {user && !user.state ? (
            <input
              required
              value={state}
              onChange={(e) => set_state(e.target.value)}
              name="state"
              type="text"
              className="lato"
              placeholder="State"
            />
          ) : (
            <></>
          )}
          {user && !user.city ? (
            <input
              required
              value={city}
              onChange={(e) => set_city(e.target.value)}
              name="city"
              type="text"
              className="lato"
              placeholder="City"
            />
          ) : (
            <></>
          )}
        </div>
        <div className={!user?.address ? "inputs" : ""}>
          {user && !user.age ? (
            <input
              required
              value={age}
              onChange={(e) => set_age(e.target.value)}
              name="age"
              type="number"
              className="lato"
              placeholder="Age"
            />
          ) : (
            <></>
          )}
          {user && !user.address ? (
            <input
              required
              value={address}
              onChange={(e) => set_address(e.target.value)}
              name="address"
              type="text"
              className="lato"
              placeholder="Address"
            />
          ) : (
            <></>
          )}
        </div>
        {user && !user.signature ? (
          <div>
            <label htmlFor="">Signature</label>
            <input
              required
              onChange={(e) => set_signature_image(e.target.files[0])}
              type="file"
              accept="image/*"
              placeholder="Signature"
            />
          </div>
        ) : (
          <></>
        )}
        {user && !user.profile_image ? (
          <div>
            <label htmlFor="">Profile Image/Logo</label>
            <input
              onChange={(e) => set_profile_image(e.target.files[0])}
              type="file"
              accept="image/*"
              placeholder="Signature"
            />
          </div>
        ) : (
          <></>
        )}
        <div>
          <label htmlFor="">Aadhaar card[front]</label>
          <input
            required
            onChange={(e) => set_adadhaar_card_f(e.target.files[0])}
            type="file"
            accept="image/*"
            placeholder="Signature"
          />
        </div>
        <div>
          <label htmlFor="">Aadhaar card[back]</label>
          <input
            required
            onChange={(e) => set_adadhaar_card_b(e.target.files[0])}
            type="file"
            accept="image/*"
            placeholder="Signature"
          />
        </div>
        <div>
          <label htmlFor="">Pan Card</label>
          <input
            required
            onChange={(e) => set_pan_card(e.target.files[0])}
            type="file"
            accept="image/*"
            placeholder="Signature"
          />
        </div>
        {extraDocuments.map((i, idx) => (
          <RenderDoc
            remove={() => {
              // console.log(
              //   extraDocuments.map((j) => (j.id === i.id ? "This" : j.id))
              // );
              setExtraDocuments([
                ...extraDocuments.filter((n) => n.id !== i.id),
              ]);
            }}
            item={i}
            extraDocuments={extraDocuments}
            setExtraDocuments={setExtraDocuments}
            key={idx}
          />
        ))}
        <button
          className="add_btn"
          onClick={(e) => {
            e.preventDefault();
            setExtraDocuments([
              ...extraDocuments,
              { title: "", file: null, id: Math.random().toString() },
            ]);
          }}
        >
          Add document
        </button>
        <div className={`c_ms ${alertMessage?.type}`}>
          {alertMessage?.message}
        </div>
        <input
          disabled={isLoading}
          type="submit"
          value={isLoading ? "loading..." : "Submit"}
        />
      </form>
    </section>
  );
}

export default Agreement;

// import { useEffect, useRef } from "react";
// import WebViewer from "@pdftron/webviewer";

// const Agreement = () => {
//   const viewer = useRef(null);

//   useEffect(() => {
//     WebViewer(
//       {
//         path: "/lib",
//         initialDoc: "/files/MFA.docx",
//         // isReadOnly: true,
//         // initialDoc:
//         //   "http://unec.edu.az/application/uploads/2014/12/pdf-sample.pdf",
//         // initialDoc: "/files/pdftron_about.pdf",
//       },
//       viewer.current
//     ).then((instance) => {
//       const { annotationManager, Annotations } = instance.Core;
//       const { WidgetFlags } = Annotations;

//       instance.UI.setHeaderItems((header) => {
//         header.push({
//           type: "actionButton",
//           img: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z"/></svg>',
//           onClick: async () => {
//             // save the annotations
//             // alert("Saved");
//             const ann = annotationManager.getAnnotationsList();
//             console.log(ann[0].Id);
//             // alert(ann[0].Author);
//           },
//         });
//       });

//       (() => {
//         // set flags for required
//         const flags = new WidgetFlags();
//         flags.set("Required", true);

//         // create a form field
//         const field = new Annotations.Forms.Field("some signature field name", {
//           type: "Sig",
//           flags,
//         });

//         // create a widget annotation
//         const widgetAnnot = new Annotations.SignatureWidgetAnnotation(field, {
//           appearance: "_DEFAULT",
//           appearances: {
//             _DEFAULT: {
//               Normal: {
//                 data: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAYdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjEuMWMqnEsAAAANSURBVBhXY/j//z8DAAj8Av6IXwbgAAAAAElFTkSuQmCC",
//                 offset: {
//                   x: 100,
//                   y: 100,
//                 },
//               },
//             },
//           },
//         });

//         // set position and size
//         widgetAnnot.PageNumber = 1;
//         widgetAnnot.X = 100;
//         widgetAnnot.Y = 50;
//         widgetAnnot.Width = 100;
//         widgetAnnot.Height = 30;

//         //add the form field and widget annotation
//         annotationManager.addAnnotation(widgetAnnot);
//         annotationManager.drawAnnotationsFromList([widgetAnnot]);
//         annotationManager.getFieldManager().addField(field);
//       })();

//       // const { documentViewer } = instance.Core;
//       // instance.UI.setHeaderItems((header) => {
//       //   header.push({
//       //     type: "actionButton",
//       //     img: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z"/></svg>',
//       //     onClick: () => {
//       //       // save the annotations
//       //       alert("Saved");
//       //     },
//       //   });
//       // });
//       // const annManager = documentViewer.getAnnotationManager();
//       // annManager.setCurrentUser("Ismail.866");
//       // // you can now call WebViewer APIs here...
//     });
//   }, []);

//   return (
//     <div className="agreement">
//       <div className="webviewer" ref={viewer} style={{ height: "100vh" }}></div>
//     </div>
//   );
// };

// export default Agreement;
