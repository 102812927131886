import React from "react";
import bvb from "../assets/bvb.png";
import about1 from "../assets/about1.png";
import about2 from "../assets/about2.png";
import about3 from "../assets/about3.png";
import about4 from "../assets/about4.png";
import cspillai from "../assets/cs_pillai.png";

function BVB(props) {
  return (
    <>
      <section
        id="about"
        style={{ paddingTop: 0, paddingBottom: 0 }}
        className="about lato container"
      >
        <div className="title">About Us</div>
        <div className="abt_">
          <div className="pics">
            <img className="about1" src={about1} alt="" />
            <img className="about2" src={about2} alt="" />
            <img className="about3" src={about3} alt="" />
            <img className="about4" src={about4} alt="" />
          </div>
          <div className="txtxs">
            <div className="card">
              <h2>Aim</h2>
              <p>
                To see you employ your money to work for our Innovations,
                startups, exits, and tech businesses and make multifold returns
                via trade-sale or M&A or IPO exits as a stay-at-home investor or
                an institutional investor ➖
              </p>
            </div>
            <div className="card">
              <h2>Purpose</h2>
              <p>
                To give innovation prudence and its business importance to the
                seasoned, to the young investor guidance and descretion of
                business and startup investing, and strive to build businesses &
                bosses simultaneously for profit & social good ➖
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="container bvb sans">
        <img className="desktop mobile" src={bvb} alt="" />
        <span>Become an Investor</span>
        <br />
        <h2>csPILLAI: The BVB</h2>
        <br />
        <br />

        <p>
          csPILLAI is a STARTUP & BUSINESS ONLINE INVESTING PLATFORM for all
          strategic and financial Pro Clients including beginning investors. Our
          clients are➖ Start-up investors, Scale-up investors, Entrepreneurial
          investors, & Mentoring investors. csPILLAI offers curated investment
          opportunities in➖ Start-up building, Start-up scaling, Manufacturing
          franchising, and Management franchising with low minimum investment
          and significant returns. Put simply, INVEST in start-ups and
          businesses of csPILLAI and PROFIT & GROW multifold.
          <br />
          <br />
          csPILLAI is BVB; BVB is csPILLAI.
          <br />
          BVB, the term "BVB" was coined by SureshPillai in 2018, stands for
          Broad Venture Builder. It has subsequently been used by csPILLAI as
          its new Business Model to achieve the goals. According to
          SureshPillai, the inventor of BVB, BVB is a profoundly modified
          Venture Builder Business Model transformed into a supercharged startup
          and business investment platform for individuals and institutional
          investors.
          <br />
          <br />
          BVB or csPILLAI achieves the goals of 1) Innovation, 2)
          Entrepreneurship, 3) Employment, & 4) Rural Development via its four
          services/products.
          <br />
          <br />
          csPILLAI, pronounced SEE-YES-PILL-EYE, is named after its real founder
          C.S. PILLAI (1940-1996), an innovative SCRAILWAY loco driver of Gooty
          R.S., India.
          <br />
          <br />
          csPILLAI ideology is to build start-ups & people simultaneously.
        </p>
        <div className="profile">
          <img src={cspillai} alt="Founder: C.S. PILLAI (1940-1996)" />
          <p>
            Founder:{" "}
            <i style={{ fontStyle: "normal" }}>C.S. PILLAI (1940-1996)</i>
          </p>
          <p>
            csPILLAI, pronounced SEE-YES-PILL-EYE, is named after its real
            founder C.S. PILLAI (1940-1996), an innovative SCR loco driver of
            Gooty R.S., India.
          </p>
        </div>
      </section>
    </>
  );
}

export default BVB;
