import React from "react";

function Card(props) {
  return (
    <div className="dcard">
      <div className="flex c_h">
        <div className="svg_">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="Iconly/Light/Profile">
              <g id="Profile">
                <path
                  id="Stroke 1"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M11.9848 15.3462C8.11714 15.3462 4.81429 15.931 4.81429 18.2729C4.81429 20.6148 8.09619 21.2205 11.9848 21.2205C15.8524 21.2205 19.1543 20.6348 19.1543 18.2938C19.1543 15.9529 15.8733 15.3462 11.9848 15.3462Z"
                  stroke="var(--primary)"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  id="Stroke 3"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M11.9848 12.0059C14.5229 12.0059 16.58 9.94782 16.58 7.40972C16.58 4.87163 14.5229 2.81448 11.9848 2.81448C9.44666 2.81448 7.38857 4.87163 7.38857 7.40972C7.38 9.93925 9.42381 11.9973 11.9524 12.0059H11.9848Z"
                  stroke="var(--primary)"
                  stroke-width="1.42857"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
            </g>
          </svg>
        </div>
        <h3>About you</h3>
      </div>
      <div className="prof">
        <div className="icon"></div>
        <input type="file" name="p_img" id="p_img" />
        <label htmlFor="p_img">Change Profile Image</label>
      </div>
      <div className="ff">
        <div className="inps">
          <div className="inp">
            <label htmlFor="first_name">First Name</label>
            <input
              id="first_name"
              type="text"
              placeholder="First Name"
              className="input_type"
            />
          </div>
          <div className="inp">
            <label htmlFor="last_name">Last Name</label>
            <input
              id="last_name"
              type="text"
              placeholder="Last Name"
              className="input_type"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card;
