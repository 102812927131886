import React, { useState } from "react";
import { useLocation } from "react-router-dom";

function ShareSite(props) {
  const [comment, setComment] = useState("");
  const { pathname } = useLocation();
  if (pathname.includes("/dashboard")) return null;
  return (
    <div className="container share lato">
      <span>
        Did you like the <strong>service ?</strong>
      </span>
      <br />
      <span style={{ fontSize: "1em" }}>
        comment & share it with your friends
      </span>
      <textarea
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        type="text"
        placeholder="Your comment"
      />
      <br />
      <br />
      <div className="sss">
        <a
          target="_blank"
          rel="noreferrer"
          href={`https://twitter.com/intent/tweet?text=${comment}&url=manufacturingbymasses.cspillai.com`}
        >
          <i className="fab fa-twitter"></i>
        </a>
        <a
          target="_blank"
          href={`https://www.facebook.com/sharer/sharer.php?u=manufacturingbymasses.cspillai.com&quote=${comment}`}
        >
          <i className="fab fa-facebook"></i>
        </a>
        <a
          target="_blank"
          href={`https://wa.me/?text=${comment}%5Cn%20manufacturingbymasses.cspillai.com`}
        >
          <i className="fab fa-whatsapp"></i>
        </a>

        <a
          target="_blank"
          href={`https://t.me/share/url?url=manufacturingbymasses.cspillai.com&text=${comment}`}
        >
          <i className="fab fa-telegram"></i>
        </a>
        <a
          target="_blank"
          href={`https://www.tumblr.com/widgets/share/tool?canonicalUrl=manufacturingbymasses.cspillai.com&caption=${comment}&tags=test%2Chello`}
        >
          <i className="fab fa-tumblr"></i>
        </a>
        <a
          target="_blank"
          href={`https://www.reddit.com/submit?url=manufacturingbymasses.cspillai.com&title=${comment}`}
        >
          <i className="fab fa-reddit"></i>
        </a>
        <a
          target="_blank"
          href={`https://www.linkedin.com/sharing/share-offsite/?url=manufacturingbymasses.cspillai.com`}
        >
          <i className="fab fa-linkedin"></i>
        </a>
        <a
          target="_blank"
          href={`https://mail.google.com/mail/u/0/?view=cm&to&su=${comment}&body=https%3A%2F%2Fmanufacturingbymasses.cspillai.com%0A&bcc&cc&fs=1&tf=1`}
        >
          <i className="fab fa-google"></i>
        </a>
      </div>
    </div>
  );
}

export default ShareSite;
