import React, { useState } from "react";
import urls from "../api/urls";
import "../css/enquire.css";
import useClient from "../hooks/useClient";
import { products } from "./Products";

function Enquire(props) {
  const [isOpned, setIsOpned] = useState(false);
  const [first_name, set_first_name] = useState("");
  const [last_name, set_last_name] = useState("");
  const [address, set_address] = useState("");
  const [product, set_product] = useState(null);
  const [comment, set_comment] = useState("");
  const [phone_number, set_phone_number] = useState("");
  const [email, set_email] = useState("");
  const [has_90x45ft, set_has_90x45ft] = useState(false);
  const [can_invest, set_can_invest] = useState(false);
  const [min_lacks, set_min_lacks] = useState(false);
  const { post, isLoading } = useClient();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      first_name,
      last_name,
      phone_number,
      email,
      address,
      comment,
      payload: JSON.stringify({
        has_90x45ft,
        can_invest,
        min_lacks,
        product,
      }),
    };
    const res = await post(urls.contact, payload);
    if (res.error) alert("Unable to send request");
    alert("Request Sent!");
  };

  return (
    <section className="container poppins enquire" id="enquire">
      <h2 className="title">Buy a Franchise; Buy a cATT</h2>
      <form onSubmit={handleSubmit} className="form">
        <div className="inputs">
          <input
            value={first_name}
            onChange={(e) => set_first_name(e.target.value)}
            required
            name="first_name"
            type="text"
            placeholder="First Name"
          />
          <input
            value={last_name}
            onChange={(e) => set_last_name(e.target.value)}
            required
            name="last_name"
            type="text"
            placeholder="Last Name"
          />
        </div>
        <div className="inputs">
          <input
            value={phone_number}
            onChange={(e) => set_phone_number(e.target.value)}
            required
            name="phone_number"
            type="text"
            placeholder="Phone Number"
          />
          <input
            value={email}
            onChange={(e) => set_email(e.target.value)}
            required
            name="email"
            type="email"
            placeholder="Email"
          />
        </div>
        <div className="inputs">
          <input
            value={address}
            onChange={(e) => set_address(e.target.value)}
            required
            name="address"
            type="text"
            placeholder="Address"
          />
          <div className={`select ${isOpned ? "opened" : ""}`}>
            <div onClick={() => setIsOpned(!isOpned)} className="val">
              {product
                ? products.find((p) => p.id === product).name
                : "Select Product"}
            </div>
            <div className="vals">
              {products.map((p) => (
                <div
                  key={p.id}
                  onClick={() => {
                    set_product(p.id);
                    setIsOpned(!isOpned);
                  }}
                >
                  {p.name}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="flex qqq">
          <span>
            Do you have 90ft x 45ft place on the outskirts of your village with
            access roads?
          </span>
          <input
            value={has_90x45ft}
            onChange={() => set_has_90x45ft(!has_90x45ft)}
            type="checkbox"
            name="have_90x45ft"
          />
        </div>
        <div className="qqq">
          <span>
            Can you invest time alongside us to hire the employees? [
            Manager(1), Supervisor(1), Fitter(4), Khalasi(4) ]
          </span>
          <input
            value={can_invest}
            onChange={(e) => set_can_invest(!can_invest)}
            type="checkbox"
            name="can_invest"
          />
        </div>
        <div className="qqq">
          <span>Minimum investment of 27 Lakhs + taxes</span>
          <input
            value={min_lacks}
            onChange={() => set_min_lacks(!min_lacks)}
            type="checkbox"
            name="min_lacks"
          />
        </div>
        <textarea
          value={comment}
          onChange={(e) => set_comment(e.target.value)}
          className="textarea"
          name="product"
          type="text"
          placeholder="Comment"
        />
        <span>
          I permit csPILLAI and its associates to call, SMS, Email or whatsapp
          me about its products and offers. This permission disallows any
          registration for DNC / NDNC registry.
        </span>
        <input type="submit" value={isLoading ? "loading..." : "Submit"} />
      </form>
    </section>
  );
}

export default Enquire;
