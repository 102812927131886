import React, { useContext, useEffect, useState } from "react";
import "../css/header.css";
import logo from "../assets/logo.svg";
import logoWhite from "../assets/logo-white.svg";
import { Link, useLocation } from "react-router-dom";
import AppContext from "../contexts/AppContext";
import store from "../utils/store";

const links = [
  {
    title: "Home",
    to: "home",
  },
  {
    title: "Products",
    to: "products",
  },
  {
    title: "Benefits",
    to: "benefits",
  },
  {
    title: "Service",
    to: "service",
  },
  {
    title: "About us",
    to: "about",
  },
  {
    title: "FAQs",
    to: "faqs",
  },
  {
    title: "Testimonials",
    to: "testimonials",
  },
  // {
  //   title: "Privacy Polices",
  //   to: "lega;",
  // },
];

function Header({ isScrolled }) {
  const [active, setActive] = useState(links[0].to);
  const [isOpened, setIsOpened] = useState(false);
  const p = useLocation();
  const { user, setUser } = useContext(AppContext);

  useEffect(() => {
    const name = p.hash.replace("#", "");
    if (name) {
      const el = document.getElementById(name);
      el.scrollIntoView({ behavior: "smooth" });
    }
  }, [p.hash]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (ent) => {
        ent.forEach((e) => {
          const name = e.target.getAttribute("id");
          setActive(name);
        });
      },
      { threshold: 1 }
    );

    links.forEach((l) => {
      const el = document.getElementById(l.to);
      if (!el) return;
      observer.observe(el);
    });
  }, []);

  if (p.pathname.includes("/dashboard")) return null;

  return (
    <header className={`lato ${isScrolled ? "scrolled" : ""}`}>
      <Link className="logo_link" to={"/"}>
        {!isScrolled ? (
          <img src={logoWhite} alt="cspillai logo" className="logo-white" />
        ) : (
          <img src={logo} alt="cspillai logo" className="logo" />
        )}
      </Link>
      <ul className={`${isOpened ? "opened" : ""}`}>
        {links.map((l, i) => (
          <Link key={i} to={`/#${l.to}`}>
            <li
              onClick={() => {
                setActive(l.to);
                setIsOpened(!isOpened);
              }}
              className={active === l.to ? "active" : ""}
            >
              {l.title}
            </li>
          </Link>
        ))}
        {user ? (
          <li
            onClick={() => {
              setUser(null);
              store.removeToken();
            }}
          >
            Logout
          </li>
        ) : (
          <Link
            onClick={() => setIsOpened(!isOpened)}
            to={"/get-started?login=true"}
          >
            <li>Login</li>
          </Link>
        )}
        <li className="btn">
          <div
            onClick={() => {
              setIsOpened(!isOpened);
              const el = document.getElementById("enquire");
              el.scrollIntoView({ behavior: "smooth" });
            }}
            className="btn-primary btn-accent"
          >
            enquire now
          </div>
        </li>
      </ul>
      <div onClick={() => setIsOpened(!isOpened)} className="menu">
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div
        onClick={() => {
          const el = document.getElementById("enquire");
          el.scrollIntoView({ behavior: "smooth" });
        }}
        className="btn-primary main-btn btn-accent"
      >
        enquire now
      </div>
    </header>
  );
}

export default Header;
