import React from "react";

function Loader({ className, ...props }) {
  return (
    <div {...props} className={`loader ${className}`}>
      Loading...
    </div>
  );
}

export default Loader;
