import React from "react";

function RefundPolicy(props) {
  return (
    <div className="content rfp lora">
      <h2>
        Thanks for choosing our csPILLAI ™ (or) Surie Innovations (OPC) Private
        Limited Services.
      </h2>
      <p>
        At
        <a href="http://cspillai.com" target="_blank" rel="noopener noreferrer">
          {" "}
          http://www.cspillai.com.
        </a>{" "}
        we want to ensure that you are 100% happy with your purchase.
      </p>
      <ul>
        <li>
          If you have technical or sales queries, do not hesitate to contact us.
        </li>
        <li>
          If after you attempt to resolve issues with Support staff and feel the
          Services you purchased do not best fit your requirements, we want to
          make things right.
        </li>
      </ul>
      <h2>Refund </h2>
      <p>
        You have 14 calendar days to request money back for the subscription on
        We-r from the date you received it. To be eligible for a return, your
        Online Purchase needs to have the receipt or proof of purchase. Our
        policy offers a full refund{" "}
        <strong>within 14 days of your date of purchase</strong>. We’d love to
        know what went wrong and how we can improve, so please include details
        about the reason for your refund request if you reach out to us
        directly.
      </p>
      <p>
        We-r and our payment process submit the refund immediately and make
        every attempt to process the refund as quickly as possible. Your
        financial institution can take up to 20 days for the refund to reflect
        in your bank account/card.
      </p>
      <p>
        <br />
        <strong style={{ color: "#ff4081" }}>
          Token or Payment Link & Refund Eligibility
        </strong>
        <br />
        <br />
      </p>
      <p>
        csPILLAI [ Surie Innovations Pvt. Ltd. ] takes the protection of its
        copyright very seriously.
      </p>
      <p>
        <strong>csPILLAI Policy</strong> offers a full-refund for online
        purchases. We want to generate great services and improve our
        communication skills, so we’d like you to write details about the reason
        for your refund request. In our token Page:
        <a
          href="https://manufacturingbymasses.cspillai.com/sponsor?type=token"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://manufacturingbymasses.cspillai.com/sponsor?type=token
        </a>{" "}
        we offer access that you pay for on a service or service related items
        or offers or products or protos or any payment including related to our
        sister concern PILLAIS GROUP( our proprietorship firm).
      </p>
      <br />
      <p>You can request a Full-Refund.</p>
      <br />
      <br />
      <h2>Cancel Subscription</h2>
      <p>
        You have the right to cancel when you like, from your account profile
        within our csPILLAI if you've subscribed to any app or software
        services.
      </p>
      <h2>UE Rights in online transactions</h2>
      <p>
        European Union clients have a right of withdrawal from certain
        transactions under the terms of EU law. Under EU rules, a{" "}
        <strong>
          trader must repair, replace, reduce the price or give you a refund
        </strong>{" "}
        if goods you bought turn out to be faulty or do not look or work as
        advertised. To better understand your rights
      </p>
      <br />
      <p>
        The 14-day returning period does not apply to all purchases. Some of the
        exemptions are:
      </p>
      <br />
      <ul>
        <li>
          plane and train tickets, as well as concert tickets, hotel bookings,
          car rental reservations and catering services for specific dates
        </li>
        <li>
          goods and drinks delivered to you by regular delivery – for example
          delivery by a milkman
        </li>
        <li>
          goods made to order or clearly personalised – such as a tailor-made
          suit
        </li>
        <li>
          sealed audio, video or computer software, such as DVDs, which you have
          unsealed upon receipt
        </li>
        <li>
          <strong>
            online digital content, if you have already started downloading or
            streaming it and you agreed that you would lose your right of
            withdrawal by starting the performance
          </strong>
        </li>
        <li>
          goods bought from a private individual rather than a company/trader
        </li>
        <li>
          urgent repairs and maintenance contracts – if you call a plumber to
          repair a leaking shower, you can't cancel the work once you have
          agreed on the price of the service
        </li>
      </ul>
      <br />
      <p>
        Check more here:
        <a
          href="https://europa.eu/youreurope/citizens/consumers/shopping/guarantees-returns/index_en.htm"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://europa.eu/youreurope/citizens/consumers/shopping/guarantees-returns/index_en.htm
        </a>
      </p>
      <h2>Shopping Rights Tool</h2>
      <p>
        If you would like to find out more about your Refund Situation we invite
        you to Check this{" "}
        <a
          href="https://europa.eu/youreurope/citizens/consumers/shopping/shopping-consumer-rights/index_en.htm"
          target="_blank"
          rel="noopener noreferrer"
        >
          Consumer Rights Tool
        </a>{" "}
        , that helps you understand your wish and if it applies to csPILLAI
        Services and Account. Check it here:
        <a
          href="https://europa.eu/youreurope/citizens/consumers/shopping/shopping-consumer-rights/index_en.htm"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://europa.eu/youreurope/citizens/consumers/shopping/shopping-consumer-rights/index_en.htm
        </a>
      </p>
      <h2>Requesting a Refund</h2>
      <br />
      <p>To request a refund:</p>
      <ul>
        <li>
          Go to we-r.world {">"}{" "}
          <strong>
            Account {">"}{" "}
            <a
              href="https://cspillai.com/refund"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://cspillai.com/refund
            </a>
          </strong>
        </li>
        <li>
          Select the <strong>Member Type</strong> of the Service/Product you
          want to refund.
        </li>
        <li>
          Write a <strong>refund reason</strong> from the drop-down menu and add
          details about your reason for the refund request.
        </li>
        <li>
          Click the button to <strong>Request a refund.</strong>
        </li>
      </ul>
      <br />
      <p>
        An automated response confirming receipt is sent to the email address
        associated with your account. Once we’ve had a chance to review the
        request, we will be in touch about the refund process.
      </p>
    </div>
  );
}

export default RefundPolicy;
