import React from "react";

function PrivacyPolicy(props) {
  return (
    <div className="content lora">
      <h2>Introduction</h2>
      <p>
        This privacy policy (&ldquo;<strong>Policy</strong>&rdquo;) describes
        how <strong>csPILLAI [ Surie Innovations Pvt. Ltd. ] in India</strong>{" "}
        (&ldquo;<strong>Company,</strong>&rdquo; &ldquo;<strong>we,</strong>
        &rdquo; and &ldquo;<strong>our</strong>&rdquo;) processes, collects,
        uses and shares personal data when using this website
        http://www.cspillai.com (the &ldquo;<strong>Website</strong>&rdquo;).
        Please read the following information carefully to understand our
        practices regarding your personal data and how we will process data.
      </p>

      <h2>Purposes of Processing</h2>

      <p>
        <strong>What is personal data?</strong>
        <br />
        We collect information about you in a range of forms, including personal
        data. As used in this Policy, &ldquo;personal data&rdquo; is as defined
        in the General Data Protection Regulation, this includes any information
        which, either alone or in combination with other information we process
        about you, identifies you as an individual, including, for example, your
        name, postal address, email address and telephone number.{" "}
      </p>
      <p>
        <strong>Why do we need your personal data?</strong>
        <br />
        We will only process your personal data in accordance with applicable
        data protection and privacy laws. We need certain personal data in order
        to provide you with access to the website. If you registered with us,
        you will have been asked to tick to agree to provide this information in
        order to access our services, purchase our products, or view our
        content. This consent provides us with the legal basis we require under
        applicable law to process your data. You maintain the right to withdraw
        such consent at any time. If you do not agree to our use of your
        personal data in line with this Policy, please do not use our website.{" "}
      </p>

      <h2>Collecting Your Personal Data</h2>

      <p>
        We collect information about you in the following ways:
        <br />
        <strong>Information You Give Us.</strong> This includes:
        <strong></strong>
      </p>
      <ul>
        <li>
          the personal data you provide when you register to use our website,
          including your name, postal address, email address, telephone number,
          username, password and demographic information (such as your gender);
          <strong></strong>
        </li>
        <li>
          the personal data that may be contained in any video, comment or other
          submission you upload or post to the website;
        </li>
        <li>
          the personal data you provide in connection with our rewards program
          and other promotions we run on the website;<strong></strong>
        </li>
        <li>
          the personal data you provide when you report a problem with our
          website or when we provide you with customer support;{" "}
          <strong></strong>
        </li>
        <li>
          the personal data you provide when you make a purchase thorough our
          website; and<strong></strong>
        </li>
        <li>
          the personal data you provide when you correspond with us by phone,
          email or otherwise.<strong></strong>
        </li>
      </ul>
      <p>
        <strong>
          Information from Social Networking Websites. <br />
        </strong>
        Our website includes interfaces that allow you to connect with social
        networking sites (each a &ldquo;SNS&rdquo;). If you connect to a SNS
        through our website, you authorize us to access, use and store the
        information that you agreed the SNS could provide to us based on your
        settings on that SNS. We will access, use and store that information in
        accordance with this Policy. You can revoke our access to the
        information you provide in this way at any time by amending the
        appropriate settings from within your account settings on the applicable
        SNS.
      </p>
      <p>
        <strong>Information Automatically Collected.</strong> <br />
        We automatically log information about you and your computer or mobile
        device when you access our website. For example, when visiting our
        website, we log your computer or mobile device operating system name and
        version, manufacturer and model, browser type, browser language, screen
        resolution, the website you visited before browsing to our website,
        pages you viewed, how long you spent on a page, access times and
        information about your use of and actions on our website. We collect
        this information about you using cookies.
      </p>
      <p>
        <strong>Automated Decision Making and Profiling. </strong>
        <br />
        We do not use your personal data for the purposes of automated
        decision-making. However, we may do so in order to fulfill obligations
        imposed by law, in which case we will inform you of any such processing
        and provide you with an opportunity to object.
      </p>

      <h2>Cookies</h2>
      <p>
        <strong>What are cookies?</strong>
        <br />
        We may collect information using &ldquo;cookies.&rdquo; Cookies are
        small data files stored on the hard drive of your computer or mobile
        device by a website. We may use both session cookies (which expire once
        you close your web browser) and persistent cookies (which stay on your
        computer or mobile device until you delete them) to provide you with a
        more personal and interactive experience on our website.
      </p>
      <p>
        We use two broad categories of cookies: (1) first party cookies, served
        directly by us to your computer or mobile device, which are used only by
        us to recognize your computer or mobile device when it revisits our
        website; and (2) third party cookies, which are served by service
        providers on our website, and can be used by such service providers to
        recognize your computer or mobile device when it visits other websites.
        <br />
        <br />
        <strong>Cookies we use</strong>
        <br />
        Our website uses the following types of cookies for the purposes set out
        below:
      </p>
      <table border="1" cellspacing="0" cellpadding="0" width="100%">
        <tr>
          <td width="145" valign="top">
            <strong>Type of cookie</strong>
          </td>
          <td width="323" valign="top">
            <p>
              <strong>Purpose </strong>
            </p>
          </td>
        </tr>
        <tr>
          <td width="145" valign="top">
            <p>
              <em>Essential Cookies </em>
            </p>
          </td>
          <td width="323" valign="top">
            <p>
              These cookies are essential to provide you with services available
              through our website and to enable you to use some of its features.
              For example, they allow you to log in to secure areas of our
              website and help the content of the pages you request load
              quickly.<strong> </strong>Without these cookies, the services that
              you have asked for cannot be provided, and we only use these
              cookies to provide you with those services.
            </p>
          </td>
        </tr>
        <tr>
          <td width="145" valign="top">
            <p>
              <em>Functionality Cookies</em>
            </p>
          </td>
          <td width="323" valign="top">
            <p>
              These cookies allow our website to remember choices you make when
              you use our website, such as remembering your language
              preferences, remembering your login details and remembering the
              changes you make to other parts of our website which you can
              customize. The purpose of these cookies is to provide you with a
              more personal experience and to avoid you having to re-enter your
              preferences every time you visit our website.{" "}
            </p>
          </td>
        </tr>
        <tr>
          <td width="145" valign="top">
            <p>
              <em>Analytics and Performance Cookies</em>
            </p>
          </td>
          <td width="323" valign="top">
            <p>
              These cookies are used to collect information about traffic to our
              website and how users use our website. The information gathered
              does not identify any individual visitor. It includes the number
              of visitors to our website, the websites that referred them to our
              website, the pages they visited on our website, what time of day
              they visited our website, whether they have visited our website
              before, and other similar information. We use this information to
              help operate our website more efficiently, to gather broad
              demographic information and to monitor the level of activity on
              our website.
            </p>
            <p>
              We use Google Analytics for this purpose. Google Analytics uses
              its own cookies. It is only used to improve how our website works.
              You can find out more information about Google Analytics cookies
              here:{" "}
              <a href="https://developers.google.com/analytics/resources/concepts/gaConceptsCookies">
                https://developers.google.com/analytics/resources/concepts/gaConceptsCookies
              </a>
            </p>
            <p>
              You can find out more about how Google protects your data here:{" "}
              <a href="https://policies.google.com/privacy">
                https://policies.google.com/privacy
              </a>
              .
            </p>
            <p>
              You can prevent the use of Google Analytics relating to your use
              of our website by downloading and installing the browser plugin
              available via this link:{" "}
              <a href="http://tools.google.com/dlpage/gaoptout?hl=en-GB">
                http://tools.google.com/dlpage/gaoptout?hl=en-GB
              </a>
              <br />
            </p>
          </td>
        </tr>
        <tr>
          <td width="145" valign="top">
            <p>
              <em>Targeted and advertising cookies</em>
            </p>
          </td>
          <td width="323" valign="top">
            <p>
              These cookies track your browsing habits to enable us to show
              advertising which is more likely to be of interest to you. These
              cookies use information about your browsing history to group you
              with other users who have similar interests. Based on that
              information, and with our permission, third party advertisers can
              place cookies to enable them to show adverts which we think will
              be relevant to your interests while you are on third party
              websites.
            </p>
            <p>
              You can disable cookies which remember your browsing habits and
              target advertising at you by visiting{" "}
              <a href="http://www.youronlinechoices.com/uk/your-ad-choices">
                http://www.youronlinechoices.com/uk/your-ad-choices
              </a>
              . If you choose to remove targeted or advertising cookies, you
              will still see adverts but they may not be relevant to you. Even
              if you do choose to remove cookies by the companies listed at the
              above link, not all companies that serve online behavioral
              advertising are included in this list, and so you may still
              receive some cookies and tailored adverts from companies that are
              not listed.
            </p>
          </td>
        </tr>
        <tr>
          <td width="145" height="63" valign="top">
            <p>
              <em>Social Media Cookies</em>
            </p>
          </td>
          <td width="323" valign="top">
            These cookies are used when you share information using a social
            media sharing button or &ldquo;like&rdquo; button on our website or
            you link your account or engage with our content on or through a
            social networking website such as Facebook, Twitter or Google+. The
            social network will record that you have done this.
          </td>
        </tr>
      </table>
      <p>
        <strong>Disabling cookies</strong>
        <br />
        You can typically remove or reject cookies via your browser settings. In
        order to do this, follow the instructions provided by your browser
        (usually located within the &ldquo;settings,&rdquo; &ldquo;help&rdquo;
        &ldquo;tools&rdquo; or &ldquo;edit&rdquo; facility). Many browsers are
        set to accept cookies until you change your settings.
      </p>
      <p>
        {" "}
        If you do not accept our cookies, you may experience some inconvenience
        in your use of our website. For example, we may not be able to recognize
        your computer or mobile device and you may need to log in every time you
        visit our website.<strong></strong>
      </p>

      <h2>Advertising</h2>
      <p>
        We may use other companies to serve third-party advertisements when you
        visit and use the website. These companies may collect and use click
        stream information, browser type, time and date, subject of
        advertisements clicked or scrolled over during your visits to the
        website and other websites in order to provide advertisements about
        goods and services likely to be of interest to you. These companies
        typically use tracking technologies to collect this information. Other
        companies' use of their tracking technologies is subject to their own
        privacy policies.
      </p>
      <ul>
        <li>
          <strong>Using Your Personal Data</strong>
        </li>
      </ul>
      <p>We may use your personal data as follows:</p>
      <ul>
        <li>
          to operate, maintain, and improve our website, products, and services;
        </li>
        <li>
          to manage your account, including to communicate with you regarding
          your account, if you have an account on our website;
        </li>
        <li>
          to operate and administer our rewards program and other promotions you
          participate in on our website;
        </li>
        <li>
          to respond to your comments and questions and to provide customer
          service;
        </li>
        <li>
          to send information including technical notices, updates, security
          alerts, and support and administrative messages;
        </li>
        <li>
          with your consent, to send you marketing e-mails about upcoming
          promotions, and other news, including information about products and
          services offered by us and our affiliates. You may opt-out of
          receiving such information at any time: such marketing emails tell you
          how to &ldquo;opt-out.&rdquo; Please note, even if you opt out of
          receiving marketing emails, we may still send you non-marketing
          emails. Non-marketing emails include emails about your account with us
          (if you have one) and our business dealings with you;
        </li>
        <li>to process payments you make via our website;</li>
        <li>
          as we believe necessary or appropriate (a) to comply with applicable
          laws; (b) to comply with lawful requests and legal process, including
          to respond to requests from public and government authorities; (c) to
          enforce our Policy; and (d) to protect our rights, privacy, safety or
          property, and/or that of you or others;
        </li>
        <li>for analysis and study services; and</li>
        <li>
          as described in the &ldquo;Sharing of your Personal Data&rdquo;
          section below.
        </li>
      </ul>

      <h2>Sharing Your Personal Data</h2>
      <p>We may share your personal data as follows:</p>
      <ul>
        <li>
          <strong>Third Parties Designated by You.</strong> We may share your
          personal data with third parties where you have provided your consent
          to do so.
        </li>
        <li>
          <strong>Our</strong> <strong>Third Party Service Providers</strong>.
          We may share your personal data with our third party service providers
          who provide services such as data analysis, payment processing,
          information technology and related infrastructure provision, customer
          service, email delivery, auditing and other similar services.
        </li>
        <li>
          <strong>Third Party Sites </strong>
        </li>
      </ul>
      <p>
        Our website may contain links to third party websites and features.
        <strong> </strong>This Policy does not cover the privacy practices of
        such third parties.<strong> </strong>These third parties have their own
        privacy policies and we do not accept any responsibility or liability
        for their websites, features or policies. Please read their privacy
        policies before you submit any data to them.
      </p>

      <h2>User Generated Content</h2>
      <p>
        You may share personal data with us when you submit user generated
        content to our website, including via our rewards program, forums,
        message boards and blogs on our website. Please note that any
        information you post or disclose on our website will become public
        information, and will be available to other users of our website and to
        the general public. We urge you to be very careful when deciding to
        disclose your personal data, or any other information, on our website.
        Such personal data and other information will not be private or
        confidential once it is published on our website.
      </p>
      <p>
        If you provide feedback to us, we may use and disclose such feedback on
        our website, provided we do not associate such feedback with your
        personal data. If you have provided your consent to do so, we may post
        your first and last name along with your feedback on our website. We
        will collect any information contained in such feedback and will treat
        the personal data in it in accordance with this Policy.
      </p>

      <h2>International Data Transfer</h2>
      <p>
        Your information, including personal data that we collect from you, may
        be transferred to, stored at and processed by us outside the country in
        which you reside, where data protection and privacy regulations may not
        offer the same level of protection as in other parts of the world. By
        accepting this Policy, you agree to this transfer, storing or
        processing. We will take all steps reasonably necessary to ensure that
        your data is treated securely and in accordance with this Policy.
      </p>

      <h2>Security</h2>
      <p>
        We seek to use reasonable organizational, technical and administrative
        measures to protect personal data within our organization.
        Unfortunately, no transmission or storage system can be guaranteed to be
        completely secure, and transmission of information via the Internet is
        not completely secure. If you have reason to believe that your
        interaction with us is no longer secure (for example, if you feel that
        the security of any account you might have with us has been
        compromised), please immediately notify us of the problem by contacting
        us.
      </p>

      <h2>Retention</h2>
      <p>
        We will only retain your personal data as long reasonably required for
        you to use the website until you close your account/cancel your
        subscription unless a longer retention period is required or permitted
        by law (for example for regulatory purposes).{" "}
      </p>

      <h2>Our Policy on Children</h2>
      <p>
        Our website is/are not directed to children under 16.<strong> </strong>
        If a parent or guardian becomes aware that his or her child has provided
        us with information without their consent, he or she should contact us.
        We will delete such information from our files as soon as reasonably
        practicable.
      </p>
      <strong>Your Rights</strong>
      <ul>
        <li>
          <strong>Opt-out. </strong>You may contact us anytime to opt-out of:
          (i) direct marketing communications; (ii) automated decision-making
          and/or profiling; (iii) our collection of sensitive personal data;
          (iv) any new processing of your personal data that we may carry out
          beyond the original purpose; or (v) the transfer of your personal data
          outside the EEA. Please note that your use of some of the website may
          be ineffective upon opt-out. <strong></strong>
        </li>
        <li>
          <strong>Access. </strong>You may access the information we hold about
          you at any time via your profile/account or by contacting us directly.{" "}
          <strong></strong>
        </li>
        <li>
          <strong>Amend. </strong>You can also contact us to update or correct
          any inaccuracies in your personal data.
        </li>
        <li>
          <strong>Move. </strong>Your personal data is portable – i.e. you to
          have the flexibility to move your data to other service providers as
          you wish.{" "}
        </li>
        <li>
          <strong>Erase and forget. </strong>In certain situations, for example
          when the information we hold about you is no longer relevant or is
          incorrect, you can request that we erase your data.{" "}
        </li>
      </ul>
      <p>
        If you wish to exercise any of these rights, please contact us. In your
        request, please make clear: (i) <strong>what</strong> personal data is
        concerned; and (ii) <strong>which of the above rights</strong> you would
        like to enforce. For your protection, we may only implement requests
        with respect to the personal data associated with the particular email
        address that you use to send us your request, and we may need to verify
        your identity before implementing your request. We will try to comply
        with your request as soon as reasonably practicable and in any event,
        within one month of your request. Please note that we may need to retain
        certain information for recordkeeping purposes and/or to complete any
        transactions that you began prior to requesting such change or deletion.
      </p>

      <h2>Complaints</h2>
      <p>
        We are committed to resolve any complaints about our collection or use
        of your personal data. If you would like to make a complaint regarding
        this Policy or our practices in relation to your personal data, please
        contact us through the information listed on our website. We will reply
        to your complaint as soon as we can and in any event, within 30 days. We
        hope to resolve any complaint brought to our attention, however if you
        feel that your complaint has not been adequately resolved, you reserve
        the right to contact your local data protection supervisory authority
      </p>

      <h2>Contact Information</h2>
      <p>
        We welcome your comments or questions about this Policy. You may contact
        us in writing or through our website<strong></strong>.
      </p>
    </div>
  );
}

export default PrivacyPolicy;
