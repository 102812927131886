import React, { useState } from "react";
import store from "../utils/store";

function AcceptCookies(props) {
  const [isAccepted, setIsAccepted] = useState(store.getItem("isAccepted"));
  const accept = () => {
    localStorage.setItem("isAccepted", true);
    // store.setItem("isAccepted", true);
    setIsAccepted(true);
  };
  if (isAccepted) return null;
  return (
    <div className="cookies_message lato">
      We are using cookies to give you the best experience on our website, to
      continue with our standard settings click "Accept".
      <div className="btns">
        <div onClick={accept}>Accept</div>
        <div onClick={() => setIsAccepted(true)}>Close</div>
      </div>
    </div>
  );
}

export default AcceptCookies;
