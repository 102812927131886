import React, { useState } from "react";
import "../css/products.css";

import trike from "../assets/products/ev-trike.png";
import grinder from "../assets/products/grinder.png";
import fan from "../assets/products/fan.png";
import inhala from "../assets/products/inhala.png";
import purifier from "../assets/products/water.png";
import doorbell from "../assets/products/doorbell.png";
import bldc1 from "../assets/products/bldc1.png";
import bldc2 from "../assets/products/bldc2.png";
import bldc3 from "../assets/products/bldc3.png";
import desk_fan from "../assets/products/desk_fan.png";
import door_lock from "../assets/products/door_lock.png";
import ventilator from "../assets/products/ventilator.png";
import useWindow from "../hooks/useWindow";

export const products = [
  {
    id: 1,
    img: grinder,
    name: "smart wet grinder",
  },
  {
    id: 2,
    img: trike,
    name: "smart ev-trike",
  },
  {
    id: 3,
    img: fan,
    name: "smart ceiling fan",
    style: { top: -20 },
  },
  {
    id: 4,
    img: purifier,
    name: "smart water purifier",
  },
  {
    id: 5,
    img: inhala,
    name: "smart inhaler",
  },
  {
    id: 6,
    img: doorbell,
    name: "smart doorbell",
    style: { width: "120%" },
  },
  {
    id: 7,
    img: bldc1,
    name: "smart bldc-1",
  },
  {
    id: 8,
    img: bldc2,
    name: "smart bldc-2",
  },
  {
    id: 9,
    img: bldc3,
    name: "smart bldc-3",
  },
  {
    id: 10,
    img: desk_fan,
    name: "smart desk fan",
    style: { width: "130%" },
  },
  {
    id: 11,
    img: door_lock,
    name: "smart door lock",
  },
  {
    id: 12,
    img: ventilator,
    name: "smart ventilator",
  },
];

const RenderIcon = ({ className = "left", ...props }) => (
  <div {...props} className={`navs ${className}`}>
    <svg
      width="7"
      height="10"
      viewBox="0 0 7 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.82877 5.02355C6.82877 5.19797 6.75043 5.35653 6.59377 5.48338L1.50213 9.44734C1.1888 9.68518 0.699224 9.68518 0.385892 9.43149C0.0921442 9.17779 0.0921454 8.78139 0.405477 8.52769L4.90962 5.02355L0.405477 1.5194C0.0921455 1.28156 0.0921443 0.869314 0.385892 0.615621C0.679641 0.361927 1.1888 0.36195 1.50213 0.599788L6.59377 4.56373C6.75043 4.69058 6.82877 4.84914 6.82877 5.02355Z"
        fill="white"
      />
    </svg>
  </div>
);

function Products(props) {
  const { width } = useWindow();
  const [active, setActive] = useState(0);
  return (
    <section id="products" className="container sans">
      <h2 className="title">cADRE Products</h2>
      <div className="sub">
        All products are patented products, designed and innovated in-house or
        in our remote micro R&D centers.
      </div>
      <RenderIcon
        onClick={() => {
          if (active === 0) return;
          setActive(active - 1);
        }}
      />
      <RenderIcon
        onClick={() => {
          if (active === products.length - 1) return;
          setActive(active + 1);
        }}
        className="right"
      />
      <div className="lists">
        {width <= 760 ? (
          <div className="product">
            <div className={`img ${active % 2 ? "org" : ""}`}>
              <img
                style={products[active].style}
                src={products[active].img}
                alt={products[active].name}
              />
            </div>
            <div className="abt">
              <h2>{products[active].name}</h2>
              <p>
                We designed products that are easy to assemble. Under our
                custom-training, the manual assembly in cATT involves the
                composing of previously manufactured components and/or sub-
                assemblies into a complete product; the masses [ foremen,
                fitters, khalasis, ......] employed at cATT perform the assembly
                operations using their inherent skill and judgement.
              </p>
            </div>
          </div>
        ) : (
          products.map((i, idx) => (
            <div key={idx} className="product">
              <div className="img">
                <img style={i.style} src={i.img} alt={i.name} />
              </div>
              <div className="abt">
                <h2>{i.name}</h2>
                <p>
                  We designed products that are easy to assemble. Under our
                  custom-training, the manual assembly in cATT involves the
                  composing of previously manufactured components and/or sub-
                  assemblies into a complete product; the masses [ foremen,
                  fitters, khalasis, ......] employed at cATT perform the
                  assembly operations using their inherent skill and judgement.
                </p>
              </div>
            </div>
          ))
        )}
      </div>
    </section>
  );
}

export default Products;
