import React, { useEffect, useState } from "react";
import "../css/sponsor.css";
import sp from "../assets/sp.png";
import shape from "../assets/shape.png";
import useClient from "../hooks/useClient";
import urls from "../api/urls";
import logo from "../assets/logowhite.png";
import pdf_anhad from "../assets/cADRE Andhra.pdf";
import { useLocation, useSearchParams } from "react-router-dom";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

function Sponsor(props) {
  const [amount, setAmount] = useState("");
  const [first_name, set_first_name] = useState("");
  const [last_name, set_last_name] = useState("");
  const [email, set_email] = useState("");
  const [inv_details, set_inv_details] = useState("");
  const [phone_number, set_phone_number] = useState("");
  const [payment_details, set_payment_details] = useState("");
  const [exec_sp_agreement, set_exec_sp_agreement] = useState(false);
  const [files, setFiles] = useState([]);
  const [address, set_address] = useState("");
  const [type, setType] = useState("sponsorship");
  const [token_for, set_token_for] = useState("");
  const [add_three_percent, setadd_three_percent] = useState(true);
  const [alertMessage, setAlertMessage] = useState({
    message: "",
    type: "",
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const { isLoading, post } = useClient();
  const [return_payment_link, set_return_payment_link] = useState(false);
  const { search } = useLocation();

  const reference = searchParams.get("reference");

  const openPaymentBox = async (result, uuid) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    // Getting the order details back
    const { amount, id: order_id, currency } = result.data;

    const options = {
      key: "rzp_live_Ekjw3iOrK99jkC", // Enter the Key ID generated from the Dashboard
      amount: amount.toString(),
      currency: currency,
      name: "csPILLAI",
      description:
        type === "token"
          ? token_for
          : amount === "2700000"
          ? "cATT Ownership"
          : "Raw Material",
      image: { logo },
      order_id: order_id,
      handler: async function (response) {
        const data = {
          orderCreationId: order_id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
          uuid,
        };

        const result = await post(urls.sponsorships_sucess, data);
        if (result.error) {
          return setAlertMessage({
            type: "error",
            message: result.error?.message,
          });
        }
        setAlertMessage({ type: "success", message: result.data.message });
        alert(result.data.message);
      },
      prefill: {
        name: `${first_name} ${last_name}`,
        email,
        contact: phone_number,
      },
      notes: {
        address,
      },
      theme: {
        color: "#10375c",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  const saveSponsorship = async () => {
    if (!amount)
      return setAlertMessage({
        type: "error",
        message: "Please add an Amount",
      });
    const docs = [];
    for (let idx = 0; idx < files.length; idx++) {
      const el = files[idx];
      if (!el.type.includes("image"))
        return setAlertMessage({
          type: "error",
          message: "All attacments must be images!",
        });

      const aformData = new FormData();
      aformData.append("file", el);
      const afileName = await post(urls.uploads, aformData);
      if (afileName.error)
        return setAlertMessage({
          visible: true,
          message: afileName.error.message,
          title: `Could not upload some files`,
        });
      docs.push(afileName.data.key);
    }
    const uuid = uuidv4();
    const res = await post(urls.sponsorships, {
      first_name,
      last_name,
      email,
      type,
      reference,
      address,
      amount: parseFloat(amount),
      phone_number,
      return_payment_link,
      uuid,
      payload: {
        add_three_percent,
        exec_sp_agreement,
        files: docs,
        inv_details,
        payment_details,
        token_for,
      },
    });
    if (res.error)
      return setAlertMessage({ type: "error", message: res.error?.message });
    setAlertMessage({ type: "success", message: res.data.message });
    // setAmount("");
    // set_first_name("");
    // set_last_name("");
    // set_email("");
    // set_phone_number("");
    // setFiles([]);
    // set_inv_details("");
    // set_payment_details("");
    // set_exec_sp_agreement(false);
    // set_return_payment_link(false);

    if (return_payment_link) {
      openPaymentBox(res, uuid);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    saveSponsorship();
  };

  useEffect(() => {
    const pattarn = search.split("=");
    if (pattarn && pattarn.length === 2 && pattarn[1] === "token") {
      setType("token");
    }
  }, []);
  return (
    <div className="sponsor_container">
      {type !== "token" && (
        <div style={{ backgroundImage: sp }} className="hero">
          <img src={sp} alt="" />
          <div className="container jjj sans">
            <h2>
              Sponsor a woman <br /> enterpreneur
            </h2>
            <p>
              Lack of sponsorship is also holding women entrepreneurs back.
              Sponsor them to our cADRE business and pave the way for their
              entrepreneurial growth and financial wellbeing. Since men hold
              most of the power positions in both the corporate and
              entrepreneurial worlds, our sponsor will, more than likely, be
              male.
            </p>
            <a href={pdf_anhad} className="btn_btn_primary">
              Download PDF
              <br />
              <span style={{ fontSize: 10 }}>[Andharapradesh sample]</span>
            </a>
          </div>
          <img src={shape} alt="." className="shape" />
        </div>
      )}
      <div className="content_ lato container">
        <h2 className="title">Amount</h2>
        <ul className="amt">
          {type !== "token" && (
            <>
              <li className="flex">
                <input
                  checked={amount === "2700000"}
                  onChange={() => {
                    setAmount("2700000");
                  }}
                  type="checkbox"
                />
                <span>
                  <span>INR 27Lacs</span> - cATT ownership
                </span>
              </li>
              <li className="flex">
                <input
                  checked={amount === "500000"}
                  onChange={() => {
                    setAmount("500000");
                  }}
                  type="checkbox"
                />
                <span>
                  <span>INR 5Lacs</span>- Raw material
                </span>
              </li>
              <li className="flex">
                <input
                  checked={amount === "650000"}
                  onChange={() => {
                    setAmount("650000");
                  }}
                  type="checkbox"
                />
                <span>
                  <span>INR 6.5Lacs</span>- Solar cATTtop
                </span>
              </li>
            </>
          )}
          <li className="flex ">
            <span className="pn">INR</span>
            <input
              className="imput_type"
              placeholder="Amount"
              onChange={(e) => setAmount(e.target.value)}
              type="number"
            />
          </li>
        </ul>

        <form className="form sp_fm" onSubmit={handleSubmit}>
          <h3>TELL US ABOUT YOURSELF</h3>
          <div className="inputs">
            <input
              name="first_name"
              required
              value={first_name}
              onChange={(e) => set_first_name(e.target.value)}
              type="text"
              placeholder="First Name"
            />
            <input
              name="last_name"
              required
              value={last_name}
              onChange={(e) => set_last_name(e.target.value)}
              type="text"
              placeholder="Last Name"
            />
          </div>
          <div className="inputs">
            <input
              required
              name="email"
              value={email}
              onChange={(e) => set_email(e.target.value)}
              type="email"
              placeholder="Email"
            />
            <input
              required
              name="phone_number"
              value={phone_number}
              onChange={(e) => set_phone_number(e.target.value)}
              type="text"
              placeholder="Phone Number"
            />
          </div>
          <br />
          <input
            type="text"
            placeholder="Address"
            name="address"
            required
            value={address}
            onChange={(e) => set_address(e.target.value)}
          />
          {type === "token" && (
            <>
              <br />
              <input
                type="text"
                placeholder="Token for"
                name="token_for"
                required
                value={token_for}
                onChange={(e) => set_token_for(e.target.value)}
              />
            </>
          )}
          <br />
          {type !== "token" && (
            <>
              <span>
                We'll call you if we have a question about your sponsorship
                amount.
              </span>
              <br />
              <span>
                Are you making this payment for someone in particular? If yes,
                write their details below.
              </span>
              <br />
              <input
                value={inv_details}
                onChange={(e) => set_inv_details(e.target.value)}
                type="text"
                name="inv_details"
                placeholder="Details"
              />
              <br />

              <div className="flex inp_">
                <input
                  checked={exec_sp_agreement}
                  onChange={() => {
                    set_exec_sp_agreement(!exec_sp_agreement);
                  }}
                  type="checkbox"
                />
                <span>Do you want to execute a sponsorship agreement?</span>
              </div>
              <br />
            </>
          )}
          <div
            // onClick={(e) => {
            //   e.preventDefault();
            //   // set_return_payment_link(!return_payment_link);
            // }}
            disabled={isLoading}
            className="btn_pp"
            style={{
              backgroundColor: "transparent",
              color: "var(--primary)",
              paddingLeft: 0,
              width: "100%",
              height: 50,
            }}
          >
            {isLoading ? (
              "loading..."
            ) : (
              <div
                style={{
                  height: 40,
                  alignItems: "center",
                }}
                className="flex align-center"
              >
                <input
                  checked={add_three_percent}
                  onChange={() => {
                    setadd_three_percent(!add_three_percent);
                  }}
                  type="checkbox"
                />
                <h5
                  style={{
                    marginLeft: 10,
                    marginBottom: 10,
                    fontWeight: "700",
                  }}
                >
                  {`Add 3% (${(parseFloat(amount || 0) * 0.03).toFixed(
                    2
                  )}) of  total amount to help cover the payment processing fees`}
                </h5>
              </div>
            )}
          </div>
          <div
            // onClick={(e) => {
            //   e.preventDefault();
            //   // set_return_payment_link(!return_payment_link);
            // }}
            disabled={isLoading}
            className="btn_pp"
            style={{
              backgroundColor: "transparent",
              color: "var(--primary)",
              paddingLeft: 0,
              width: "100%",
              height: 50,
            }}
          >
            {isLoading ? (
              "loading..."
            ) : (
              <div
                style={{
                  height: 40,
                  alignItems: "center",
                }}
                className="flex align-center"
              >
                <input
                  checked={return_payment_link}
                  onChange={() => {
                    set_return_payment_link(!return_payment_link);
                  }}
                  type="checkbox"
                />
                <h4
                  style={{
                    marginLeft: 10,
                    marginBottom: 10,
                    fontWeight: "900",
                  }}
                >
                  Pay Online
                </h4>
              </div>
            )}
          </div>
          <center>
            <br />
            <span>Or</span>
          </center>
          <br />
          <span>
            If you have remitted token to the following Bank account already,
            enter the payment details-
          </span>
          <br />
          <strong style={{ fontWeight: "600" }}>
            PILLAIS GROUP
            <br />
            Current A/C no: 062711100001918
            <br />
            UBI
            <br />
            IFSC: UBIN0806277
            <br />
            Vashi, Navi Mumbai- 400703 <br /> Maharashtra <br /> India
          </strong>
          <br />
          <br />
          <textarea
            value={payment_details}
            onChange={(e) => set_payment_details(e.target.value)}
            placeholder="Payment details"
            name="payment_details"
          ></textarea>
          <br />
          <label htmlFor="fff">Add atachments(image)</label>
          <input
            multiple
            onChange={(e) => setFiles(e.target.files)}
            id="fff"
            type="file"
            accept="image/*"
            placeholder="Add atachment(image)"
          />
          <div className={`c_ms ${alertMessage?.type}`}>
            {alertMessage?.message}
          </div>
          <input
            disabled={isLoading}
            type="submit"
            value={isLoading ? "loading..." : "Send"}
          />
        </form>
      </div>
    </div>
  );
}

export default Sponsor;
