import React, { useState } from "react";
import "../css/footer.css";
import SocialIcons from "./Socials";
import lic from "../assets/lic.png";
import { Link, useLocation } from "react-router-dom";
import useClient from "../hooks/useClient";
import urls from "../api/urls";

const links = [
  {
    title: "csPILLAI",
    href: "https://cspillai.com",
  },
  {
    title: "Invest2Exit",
    href: "https://cspillai.com",
  },
  {
    title: "Invest2Multiply",
    href: "https://cspillai.com",
  },
  {
    title: "Invest2Enterprise",
    href: "https://cspillai.com",
  },
  {
    title: "Invest2Excel",
    href: "https://cspillai.com",
  },
  {
    title: "Sponsor",
    href: "https://cspillai.com/invest-to-enterprise",
  },
  {
    title: "Token",
    href: "/sponsorship?type=token",
  },
];

const policies = [
  "Privacy Policy",

  "Terms Of Service",

  "Earnings Disclaimer",
  "Terms",
  "Copyright Notice",
  "Refund Policy",
];

function Footer(props) {
  const { isLoading, post } = useClient();
  const [email, setEmail] = useState("");
  const [alertMessage, setAlertMessage] = useState({
    message: "",
    type: "",
  });

  const { pathname } = useLocation();

  const register = async () => {
    if (!email) return;
    const res = await post(urls.news_letters, {
      email,
    });
    if (res.error)
      return setAlertMessage({ type: "error", message: res.error?.message });
    setEmail("");
    setAlertMessage({ type: "success", message: "Thanks for subscribing!" });
  };

  if (pathname.includes("/dashboard")) return null;

  return (
    <footer className="container lato">
      <div className="footer">
        <div className="dist">
          <h2 className="lora">csPILLAI</h2>

          <p>
            <span>Startup and Business Online Investment Platform!</span> <br />
            WE BUILD & SELL START-UPS, SCALE-UPS, ENTREPRENEURSHIPS, AND
            ASSOCIATESHIPS!
          </p>
          <SocialIcons />
        </div>
        <div className="dist">
          <h3>Useful Links</h3>
          <ul>
            {links.map((link, idx) => (
              <li key={idx}>
                <img src={lic} alt="" />
                <a href={link.href} target="_blank" rel="noopener noreferrer">
                  {link.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="dist">
          <h3>Our Polices</h3>
          <ul>
            {policies.map((link, idx) => (
              <li key={idx}>
                <img src={lic} alt="" />
                <Link
                  to={
                    "/legal/" +
                    link.toLowerCase().replace(" ", "-").replace(" ", "-")
                  }
                >
                  {link}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="dist nnn">
          <h3>Subscribe to our Newsletter</h3>
          <div className="input">
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              placeholder="Your Email Address"
            />
            <div onClick={register}>{isLoading ? "loading..." : "Submit"}</div>
          </div>
        </div>
        <div className={`c_ms njs ${alertMessage?.type}`}>
          {alertMessage?.message}
        </div>
        {/* <div className="dist desktop">
          <h3>Spaces</h3>
          <ul>
            <li>Home</li>
            <li>Benefits</li>
          </ul>
        </div>
        <div className="dist desktop">
          <h3>Company</h3>
          <ul>
            <li>About Us</li>
            <li>Products</li>
            <li>Goals</li>
            <li>Services</li>
          </ul>
        </div>
        <div className="dist desktop">
          <h3>Support</h3>
          <ul>
            <li>FAQs</li>
            <li>Privacy Policy</li>
            <li>Help</li>
          </ul>
        </div>
        <div className="dist">
          <h3>Contact Us</h3>
          <ul>
            <li>
              <a
                href="mailto:mail@cspillai.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                mail@cspillai.com
              </a>
            </li>
            <li>
              <a
                href="mailto:contact@cspillai.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                contact@cspillai.com
              </a>
            </li>
            <br />
            <br />
            <li>
              <a
                style={{ fontWeight: "700" }}
                href="tel:18002124503"
                target="_blank"
                rel="noopener noreferrer"
              >
                18002124503 (Toll-free national)
              </a>
            </li>
            <li>
              <a
                style={{ fontWeight: "700" }}
                href="tel:+918850762306"
                target="_blank"
                rel="noopener noreferrer"
              >
                +918850762306 (international)
              </a>
            </li>
          </ul>
        </div>
        <div className="dist">
          <h3>Location</h3>
          <p>
            10th floor-Tower 1, Sea woods Grand Central, Sector 40, Seawoods
            Railway Station, Navi Mumbai, Maharashtra- 400706
          </p>
        </div> */}
      </div>
      <p className="f_note">
        Nothing on this website should be defined as an offer to sell, an act of
        asking to buy an offer, or a proposal for any assurance by csPILLAI or
        any third party. You are singularly responsible for judging whether any
        purchase/investment strategy, safety/security/assurance, or related
        purchase/investment is proper for you based on your investment goals,
        financial situations, and uncertainty threshold. Investors and users
        should discuss judicial, tax, insurance, or financing advice with
        authorized legal specialists and finance advisors. Would you mind
        emailing for additional bits of advice?
      </p>
      <div className="copy">
        Copyright &copy; 2021 csPILLAI All Rights Reserved.
      </div>
    </footer>
  );
}

export default Footer;
