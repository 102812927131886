const urls = {
  login: "/investors/login",
  sign_up: "/investors/",
  me: "/investors/me",
  contact: "/admin/contact",
  uploads: "/uploads",
  news_letters: "/news-letters",
  i2e: {
    base_url: "/invest2enterprise",
    mfa: "/invest2enterprise/mfa",
  },
  auth: {
    validate: "/auth/validate",
    generate: "/auth/generate",
  },
  sponsorships: "/sponsorships",
  sponsorships_sucess: "/sponsorships/success",
};

export default urls;
