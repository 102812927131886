import React, { useEffect, useState } from "react";

function NotificationIcon(props) {
  const [isMouseIn, setIsMouseIn] = useState(false);
  const setIsMouseInOn = () => setIsMouseIn(true);
  const setIsMouseInOff = () => setIsMouseIn(false);

  return (
    <>
      <div
        onMouseEnter={setIsMouseInOn}
        onMouseLeave={setIsMouseInOff}
        className="bell"
        style={{ position: "relative" }}
      >
        <svg
          width="20"
          height="22"
          viewBox="0 0 20 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10 16.8476C15.6392 16.8476 18.2481 16.1242 18.5 13.2205C18.5 10.3188 16.6812 10.5054 16.6812 6.94511C16.6812 4.16414 14.0452 1 10 1C5.95477 1 3.31885 4.16414 3.31885 6.94511C3.31885 10.5054 1.5 10.3188 1.5 13.2205C1.75295 16.1352 4.36177 16.8476 10 16.8476Z"
            stroke="var(--primary)"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12.3888 19.8572C11.0247 21.3719 8.89665 21.3899 7.51947 19.8572"
            stroke="var(--primary)"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        {isMouseIn && (
          <div className="shadow notifications">
            <h2>Notifications</h2>
            <ul style={{ display: "block" }}>
              <li>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Voluptatibus, consequatur. Vitae aut id soluta cumque similique,
                sunt libero rerum sequi commodi, sit delectus debitis natus enim
                exercitationem, expedita perspiciatis inventore?
                <span>Today at 13:33</span>
              </li>
              <li>
                Libero quas cumque architecto deleniti sint culpa, veritatis
                voluptates tempore accusamus id facilis quis cum quia minus
                incidunt expedita sunt quisquam illum velit aperiam
                reprehenderit maiores quae necessitatibus! Earum, expedita.
                <span>Today at 13:33</span>
              </li>
              <li>
                Magnam facere quaerat error laborum eius quibusdam omnis illum
                nisi fugit cumque possimus nobis minus, dolores porro, aliquam
                sequi impedit dicta molestias similique? Eveniet, modi maiores
                dolorem officiis quidem aperiam!
                <span>Today at 13:33</span>
              </li>
            </ul>
          </div>
        )}
      </div>
    </>
  );
}

export default NotificationIcon;
