import React from "react";
import Card from "../com/Card";

function Profile(props) {
  return (
    <div className="container profile">
      <Card />
    </div>
  );
}

export default Profile;
