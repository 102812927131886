import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import urls from "../api/urls";
import AppContext from "../contexts/AppContext";
import "../css/get-started.css";
import useClient from "../hooks/useClient";
import store from "../utils/store";

function GetStarted(props) {
  const [isOrganization, setIsOrganization] = useState(false);
  const [registerationEnd, setRegistrationEnd] = useState(false);
  const [email, setEmail] = useState("");
  const [vtoken, setVToken] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [orgName, setOrgName] = useState("");
  const [isSignin, setIsSignIn] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const navigate = useNavigate();
  const [tokens, setTokenss] = useState({});
  const { search } = useLocation();
  const [alertMessage, setAlertMessage] = useState({
    message: "",
    type: "",
  });

  const { post, isLoading } = useClient();
  const { setUser, user, setTokens } = useContext(AppContext);

  const requestForToken = async (e) => {
    const cres = await post(
      urls.auth.generate,
      {},
      {
        headers: tokens,
      }
    );
  };

  const submitToken = async (e) => {
    e.preventDefault();
    const res = await post(
      urls.auth.validate,
      {
        token: vtoken,
      },
      { headers: tokens }
    );
    //{ headers: tokens }
    if (res.error)
      return setAlertMessage({
        type: "error",
        message: res.error?.message,
      });
    setAlertMessage({
      type: "",
      message: "",
    });
    navigate("/agreement");
  };

  const signup = async (e) => {
    e.preventDefault();
    let res;
    if (!isSignin) {
      if (password !== password2)
        return setAlertMessage({
          type: "error",
          message: "Passwords not matching",
        });
      if (
        !email ||
        !password ||
        (!isOrganization && (!firstName || !lastName)) ||
        (isOrganization && !orgName)
      ) {
        return setAlertMessage({
          type: "error",
          message: "Fill out required fields",
        });
      }
      res = await post(urls.sign_up, {
        email,
        password,
        first_name: firstName,
        last_name: lastName,
        organisation_name: orgName,
        services: [3],
      });
      if (res.error)
        return setAlertMessage({ type: "error", message: res.error?.message });
    }
    res = await post(urls.login, { email, password });
    if (res.error)
      return setAlertMessage({ type: "error", message: res.error?.message });
    setAlertMessage({ type: "success", message: "Account Registered" });
    setAlertMessage(null);
    store.setAccessToken(res.data.data.tokens.access);
    store.setRefreshToken(res.data.data.tokens.refresh);
    setUser(res.data.data.profile);
    setTokenss({
      "x-access-token": res.data.data.tokens.access,
      "x-refresh-token": res.data.data.tokens.refresh,
    });
    setTokens({
      "x-access-token": res.data.data.tokens.access,
      "x-refresh-token": res.data.data.tokens.refresh,
    });
    if (!res.data.data.profile.is_verified) {
      const cres = await post(
        urls.auth.generate,
        {},
        {
          headers: {
            "x-access-token": res.data.data.tokens.access,
            "x-refresh-token": res.data.data.tokens.refresh,
          },
        }
      );
      if (cres.error)
        return setAlertMessage({ type: "error", message: cres.error?.message });
    }

    if (isSignin && !res.data.data.profile.is_verified) {
      navigate("/agreement");
    } else {
      setRegistrationEnd(true);
    }
    // submitToken();
    // navigate("/");
  };

  useEffect(() => {
    if (user) {
      if (user.is_verified) {
        navigate("/agreement");
      } else {
        setTokenss({
          "x-access-token": store.getAccessToken(),
          "x-refresh-token": store.getRefreshToken(),
        });
        (async () => {
          await requestForToken();
          setRegistrationEnd(true);
        })();
      }
    }
  }, [user]);

  useEffect(() => {
    if (search === "?login=true") {
      setIsSignIn(true);
    }
  }, []);

  return (
    <section className="poppins get_started container">
      {registerationEnd ? (
        <form onSubmit={submitToken} className="form sss">
          <span>Verification code was sent to {email}</span>
          <div className="bbb" onClick={requestForToken}>
            Resend
          </div>
          <br />
          <input
            value={vtoken}
            onChange={(e) => setVToken(e.target.value)}
            name="code"
            type="number"
            className="lato"
            placeholder="Confirmation Code"
          />
          <div className={`c_ms ${alertMessage?.type}`}>
            {alertMessage?.message}
          </div>
          <input
            disabled={isLoading}
            type="submit"
            value={isLoading ? "loading..." : "Continue"}
          />
        </form>
      ) : (
        <form onSubmit={signup} className="form">
          <h3>{`Sign ${isSignin ? "in" : "up"} to continue`}</h3>
          {!isSignin &&
            (isOrganization ? (
              <input
                value={orgName}
                onChange={(e) => setOrgName(e.target.value)}
                className="lato"
                name="org_name"
                type="text"
                required={isOrganization}
                placeholder="Organization Name"
              />
            ) : (
              <div className="inputs poppins">
                <input
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  className="lato"
                  name="first_name"
                  type="text"
                  required={!isOrganization}
                  placeholder="First Name"
                />
                <input
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required={!isOrganization}
                  className="lato"
                  name="last_name"
                  type="text"
                  placeholder="Last Name"
                />
              </div>
            ))}
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="lato"
            required
            type="email"
            placeholder="Email"
          />
          <div className={isSignin ? "" : "inputs"}>
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="lato"
              required
              name="password"
              type="password"
              placeholder="Password"
            />
            {!isSignin && (
              <input
                value={password2}
                onChange={(e) => setPassword2(e.target.value)}
                className="lato"
                required
                name="password2"
                type="password"
                placeholder="Confirm Password"
              />
            )}
          </div>
          <input
            type="hidden"
            name="recaptcha_response"
            id="recaptchaResponse"
          />
          <div className={`c_ms ${alertMessage?.type}`}>
            {alertMessage?.message}
          </div>
          <div className="acc flex">
            <input
              disabled={isLoading}
              type="submit"
              className="poppins"
              value={
                isLoading ? "loading..." : isSignin ? "Login" : "Get Started"
              }
            />
            {!isSignin && (
              <div className="rad flex">
                <input
                  value={isOrganization}
                  onChange={() => setIsOrganization(!isOrganization)}
                  type="checkbox"
                />
                <span>Register Organizational Account</span>
              </div>
            )}
          </div>
          <div
            onClick={() => setIsSignIn(!isSignin)}
            className="bb_ss"
          >{`Sign ${isSignin ? "up" : "in"} here`}</div>
        </form>
      )}
    </section>
  );
}

export default GetStarted;
