import React from "react";
import "../css/home.css";
import catt from "../assets/catt.png";
import shape from "../assets/shape.png";
import { Link } from "react-router-dom";

function Home(props) {
  return (
    <main id="home">
      <div className="lora inf">
        <h2>
          Hello,
          <br />
          Entrepreneurial Investor!
        </h2>
        <p>
          Invest (and) become a manufacturing franchise partner or a
          micro-entrepreneur (and) enable the masses to manufacture (and) have
          an app to run the franchise from anywhere (and) make money month after
          month (and) multiply investments!
        </p>
        <div className="txt_">
          <span>
            Invest Rs. 27 lacs in a cADRE manufacturing franchise business and
            become your own boss while profiting (23% CAGR/5yr)!
          </span>
        </div>
        <div className="flex">
          <Link to={"/get-started"} className="btn-primary sans">
            get started
          </Link>
          <div
            onClick={() => {
              const el = document.getElementById("enquire");
              el.scrollIntoView({ behavior: "smooth" });
            }}
            className="btn-primary sans outline mobile"
          >
            enquire
          </div>
        </div>

        <img src={shape} alt="." className="shape" />
      </div>

      <img className="watar-mark" src={catt} alt="cspillai catt" />
      <img className="catt" src={catt} alt="cspillai catt" />
    </main>
  );
}

export default Home;
